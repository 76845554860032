import PasswordValidator from 'password-validator';

const passwordValidator = new PasswordValidator();
passwordValidator
  .is()
  .min(8)
  //	.is().max(30)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .not()
  .spaces()
  .is()
  .not()
  .oneOf(['Passw0rd', 'P@ssw0rd', 'Password1', 'Password2', 'Password123']);

export default passwordValidator;
