import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import RootStore from '../../stores/RootStore';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { CustPaper } from '../../components/customizations/CustPaper';
import { CustContainer } from '../../components/customizations/CustContainer';

interface IProps {
  rootStore?: RootStore;
}

export const GetStarted = inject('rootStore')((props: IProps) => {
  
  return (
    <Box pt={2} pb={2}>
      <CustContainer>
        <CustPaper>
          <Box mt={2}>
            <Typography paragraph={true}>Leuk dat je je steen wilt registreren bij Rubble.</Typography>
            <Typography paragraph={true}>We vragen je in de hierop volgende stappen wat informatie over de steen in te voeren, een mooie foto te uploaden en een Rubble accoount aan te maken.</Typography>
            <Typography paragraph={true}>Daarna is het genieten van alle leuke reacties die de vinders achterlaten.</Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Link to='/add-pebble'>
              <Button variant="contained" color="primary">Beginnen</Button>
            </Link>
          </Box>
        </CustPaper>
      </CustContainer>
    </Box>
  );
});
