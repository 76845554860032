import React from 'react';
import { Container } from '@material-ui/core';

interface IProps {
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  style?: React.CSSProperties;
  disableGutters?: boolean;
}

export const CustContainer = (props: React.PropsWithChildren<IProps>) => (
  <Container {...props} disableGutters={props.disableGutters}>
    <>{props.children}</>
  </Container>
);

CustContainer.defaultProps = {
  maxWidth: 'lg',
  disableGutters: false
}
