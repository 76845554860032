import { observe } from "mobx";


export const syncHistoryWithStore = (history, store) => {

	store.setHistory(history);

	const handleLocationChange = (location) => {
		store.updateLocation(location);
	};

	const unsubscribeFromHistory = history.listen(handleLocationChange);
	handleLocationChange(history.location);

	const subscribe = (listener) => {
		const onStoreChange = () => {
			const rawLocation = { ...store.location };
			listener(rawLocation, history.action);
		};

		// Listen for changes to location state in store
		const unsubscribeFromStore = observe(store, 'location', onStoreChange);

		listener(store.location, history.action);

		return unsubscribeFromStore;
	};

	history.subscribe = subscribe;
	history.unsubscribe = unsubscribeFromHistory;

	return history;
}