import { inject } from 'mobx-react';
import { SignInDialog } from '../../components/SignInDialog';
import RootStore from '../../stores/RootStore';

const SignIn = inject('rootStore')((props: { rootStore?: RootStore }) => {
  const onSignInDialogClose = () => {
    props.rootStore?.routerStore.push('/');
  };

  return <SignInDialog open={true} onClose={onSignInDialogClose} />;
});

export { SignIn };
