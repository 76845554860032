import React, { useState } from 'react';
import { withTheme } from '@material-ui/core';
import { PebbleLoader } from '../../../components/loaders/PebbleLoader';
import { Pebble } from '../../../models/Pebble';
import RootStore from '../../../stores/RootStore';
import { inject } from 'mobx-react';
import { PebbleDetails } from './PebbleDetails';
import { Switch, Route } from 'react-router-dom';

interface IProps {
  rootStore?: RootStore;
  id: string;
}

const PebbleIndex = withTheme(
  inject('rootStore')((props: IProps) => {
    const [pebble, setPebble] = useState<Pebble>();

    const onPebbleLoaded = (pebble: Pebble) => {
      setPebble(pebble);
    };

    return (
      <PebbleLoader pebbleId={props.id} onLoaded={onPebbleLoaded}>
        {pebble && (
          <Switch>
            <Route exact={true} path="/pebbles/:id" render={() => <PebbleDetails pebble={pebble} />} />
          </Switch>
        )}
      </PebbleLoader>
    );
  })
);

export { PebbleIndex };
