import { Box, Container, Typography } from '@material-ui/core';
import { inject } from 'mobx-react';
import { CustContainer } from '../../../components/customizations/CustContainer';
import RootStore from '../../../stores/RootStore';
import { CustPaper } from '../../../components/customizations/CustPaper';

interface IProps {
  rootStore?: RootStore;
}

export const PebbleNotFound = inject('rootStore')((props: IProps) => (
  <Box pt={2} pb={2}>
    <CustContainer
      style={{
        textAlign: 'center'
      }}
    >
      <CustPaper>
        <Box
          style={{
            textAlign: 'center'
          }}
        >
          <Typography variant="h3" component="h1">
            Helaas,
          </Typography>
          <Typography paragraph={true}>
            de steen is niet gevonden.
          </Typography>
          <Typography paragraph={true}>
            Controleer of je de code goed hebt ingevuld en probeer het nogmaals.
          </Typography>
          <Typography  paragraph={true}>
            Niet gelukt? Stuur dan een email met een foto van de voor- en achterkant van de steen naar info@rubble.app en we proberen de steen voor je te vinden.
          </Typography>
        </Box>
      </CustPaper>
    </CustContainer>
  </Box>
));
