import { Typography } from '@material-ui/core';

interface IProps {
  text: string
}

const Title = (props: IProps) => (
  <Typography variant="h4" component="h1" style={{ marginBottom: '30px' }}>
    {props.text}
  </Typography>
);

export { Title }
