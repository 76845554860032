import { Box, Card, CardContent, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((_theme: Theme) => ({
  root: {}
}));

interface IProps {
  index: string;
}

const InfoStep = (props: React.PropsWithChildren<IProps>) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex">
          <Box width="70" display="flex" alignItems="center">
            <Box textAlign="center">
              <Typography variant="body1" component="div">
                STAP
              </Typography>
              <Typography variant="h3" component="div" style={{ lineHeight: 1 }}>
                {props.index}
              </Typography>
            </Box>
          </Box>
          <Box flexGrow={1} style={{ paddingLeft: '30px' }}>
            {props.children}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export { InfoStep };
