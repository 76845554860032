import React, { useState } from 'react';
import { Box, withTheme } from '@material-ui/core';
import { PebbleLoader } from '../../components/loaders/PebbleLoader';
import { Pebble } from '../../models/Pebble';
import RootStore from '../../stores/RootStore';
import { PebbleFound } from './components/PebbleFound';
import { inject } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import { LogPebble } from './components/LogPebble';
import { LogPebbleSuccess } from './components/LogPebbleSuccess';
import { PebbleNotFound } from './components/PebbleNotFound';

interface IProps {
  rootStore?: RootStore;
  code: string;
}

const Lookup = withTheme(
  inject('rootStore')((props: IProps) => {
    const [pebble, setPebble] = useState<Pebble>();
    const [showNotFound, setShowNotFound] = useState(false);
    const onPebbleLoaded = (pebble: Pebble) => {
      setPebble(pebble);
    };
    const onPebbleNotFound = () => {
      setShowNotFound(true);
    };
    const onLogSuccess = () => {
      props.rootStore?.routerStore.push(`/lookup/${props.code}/log-success`);
    }

    return (
      <>
        <Box mt={2}>
          <PebbleLoader code={props.code} onLoaded={onPebbleLoaded} onNotFound={onPebbleNotFound}>
            <>
              {pebble && 
                <Switch>
                  <Route exact={true} path="/lookup/:code" render={() => <PebbleFound pebble={pebble}></PebbleFound>} />
                  <Route exact={true} path="/lookup/:code/log" render={() => <LogPebble pebble={pebble} onSuccess={onLogSuccess} />} />
                  <Route exact={true} path="/lookup/:code/log-success" render={() => <LogPebbleSuccess pebble={pebble} />} />
                </Switch>
              }
            </>
          </PebbleLoader>
          {showNotFound && 
            <PebbleNotFound />
          }
        </Box>
      </>
    );
  })
);

export { Lookup };
