import React, { useEffect, useState } from 'react';
import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import RootStore from '../../stores/RootStore';
import { inject } from 'mobx-react';
import { CustContainer } from '../../components/customizations/CustContainer';
import { CustPaper } from '../../components/customizations/CustPaper';
import { StepForm } from '../../components/wizards/StepForm';
import { ImageUploader } from '../../components/ImageUploader';
import { AuthMode, AuthModeSelectionPartial } from '../../components/partials/AuthModeSelectionPartial';
import { SignInDialog, SignInDialogStateType } from '../../components/SignInDialog';
import { Alert } from '@material-ui/lab';
import { Pebble } from '../../models/Pebble';

interface IValues {
  title: string | undefined;
  description: string | undefined;
  pictureFile: File | undefined;
  pictureUrl: string | undefined;
}

interface IValidation {
  title: boolean | undefined;
  picture: boolean | undefined;
  authentication: boolean | undefined;
  authMode?: boolean;
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

interface IProps {
  rootStore?: RootStore,
  onSuccess?: (pebble: Pebble) => void
}

type StepType = 'auth-mode' | 'welcome';

export const AddPebble = inject('rootStore')((props: IProps) => {
  const classes = useStyles();
  const [authMode, setAuthMode] = useState<AuthMode>('');
  const [values, setValues] = useState<IValues>({
    title: undefined,
    description: undefined,
    pictureFile: undefined,
    pictureUrl: undefined
  });
  const [validations, setValidations] = useState<IValidation>({
    title: undefined,
    picture: undefined,
    authentication: undefined
  });
  const [signInDialogOpen, setSignInDialogOpen] = useState<boolean>(false);
  const [signInDialogState, setSignInDialogState] = useState<SignInDialogStateType>('signin');
  const [skippedSteps, setSkippedSteps] = useState<Map<StepType, boolean>>(new Map([[
    'auth-mode', false,
  ],[
    'welcome', true
  ]]));

  const authModeNextButtonTexts = new Map<AuthMode, string>([
    ['signIn', 'Inloggen'],
    ['signUp', 'Account aanmaken'],
    ['', 'Volgende']
  ]);

  useEffect(() => {
    const isAuthenticated = props.rootStore?.authStore.isAuthenticated || false;
    setSkippedSteps(map => new Map(map)
      .set('auth-mode', isAuthenticated)
    );
  }, [authMode]);

  const onSignInDialogClose = async () => {
    const isAuthenticated = props.rootStore?.authStore.isAuthenticated || false;

    setSkippedSteps(map => new Map(map)
      .set('auth-mode', isAuthenticated)
      .set('welcome', false)
    );
    setSignInDialogOpen(false);
  }

  const handleTextChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onPictureChanged = (pictureFile: File, pictureUrl: string) => {
    setValues({ ...values, pictureFile, pictureUrl });
  };

  const onNextTitle = async () => {
    const valid = values.title !== undefined && values.title.trim() !== '';;
    setValidations({ ...validations, title: valid})
    return valid;
  }

  const onNextPicture = async () => {
    const valid = values.pictureFile !== undefined;
    setValidations({ ...validations, picture: valid})
    return valid;
  }

  const onNextAuthMode = async () => {
    const valid = authMode !== '';
    setValidations({ ...validations, authMode: valid});
    
    if (valid) {
      if (authMode == 'signIn') {
        setSignInDialogOpen(true);
      } else if (authMode === 'signUp') {
        setSignInDialogState('register');
        setSignInDialogOpen(true);        
      }
    }
    return false;
  }

  const onFinish = async () => {
    const pebble = await props.rootStore!.pebbleStore.createPebble({
      title: values.title ?? '',
      description: values.description
    });
    await pebble.uploadPicture(values.pictureFile!);
    if (props.onSuccess) props.onSuccess(pebble);
  }

  return (
    <Box pt={2} pb={2}>
      <CustContainer>
        <CustPaper>
          <StepForm
            title="Steen registreren"
            steps={[{
              title: 'Welke naam wil je aan je steen geven?',
              component: (
                <>
                  <TextField
                    id="title"
                    required
                    className={classes.textField}
                    variant="filled"
                    defaultValue={values.title}
                    onChange={handleTextChange('title')}
                    error={validations.title !== undefined && !validations.title}
                    fullWidth
                    inputProps={{
                      'aria-label': 'naam'
                    }}
                  />
                  {validations.title !== undefined && !validations.title &&
                    <Box mt={2}>
                      <Alert severity="error">Een naam is verplicht</Alert>
                    </Box>
                  }
                </>
              ),
              onNext: onNextTitle
            }, {
              title: 'Geef een korte omschrijving over je steen',
              component: (
                <>
                  <TextField
                    id="description"
                    required
                    className={classes.textField}
                    variant="filled"
                    defaultValue={values.description}
                    onChange={handleTextChange('description')}
                    fullWidth
                    rows={7}
                    multiline={true}
                    inputProps={{
                      'aria-label': 'omschrijving'
                    }}
                  />
                </>
              )              
            }, {
              title: 'Upload je mooiste foto',
              component: (
                <>
                  <ImageUploader
                    buttonText="Selecteer foto"
                    onChange={onPictureChanged}
                    file={values.pictureFile}
                    imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                    label="Maximale grootte 2Mb"
                    noImageSelectedLabel="Geen foto geselecteerd. Klik op de knop Selecteer Foto"
                    fileSizeError=" is te groot"
                    fileTypeError=" is een niet ondersteund formaat"
                    maxFileSize={2 * 1024 * 1024}
                  />
                  {validations.picture !== undefined && !validations.picture && (
                    <Box mt={2}>
                      <Alert severity="error">Een foto is verplicht</Alert>
                    </Box>
                  )}
                </>
              ),
              onNext: onNextPicture
            }, {
              title: 'Aanmelden',
              component: (
                <AuthModeSelectionPartial
                  value={authMode}
                  anonymousEnabled={false}
                  onChange={setAuthMode}
                  error={validations?.authMode !== undefined && !validations?.authMode}
                />
              ),
              nextButtonText: authModeNextButtonTexts.get(authMode),
              skip: skippedSteps.get('auth-mode'),
              onNext: onNextAuthMode
            }, {
              title: 'Afronden',
              component: (
                <>
                  <Typography>Welkom {props.rootStore?.authStore.user?.firstName}, klik op voltooien om je steen toe te voegen.</Typography>
                </>
              ),
              skip: skippedSteps.get('welcome')
            }]}
            onFinish={onFinish} />
        </CustPaper>
      </CustContainer>
      <SignInDialog 
        open={signInDialogOpen}
        onClose={onSignInDialogClose}
        state={signInDialogState}
      />
    </Box>
  )
})
