export enum ErrorResponseCodes {
  FORBIDDEN = '403.001',
  NOT_FOUND = '404.001',
  USER_WITH_SAME_USERNAME_EXISTS = '10001',
  PASSWORD_RESET_EXPIRED = '10002',
  USER_ACTIVATION_EXPIRED = '10003',
  USER_NOT_ACTIVATED = '10004',
  USER_IS_BLOCKED = '10005',
  USER_ACTIVATION_FAILED = '10006',
  INVALID_REQUEST = '10007',
  USER_NOT_FOUND = '10008'
}
