import { action, observable, makeObservable } from 'mobx';
import RootStore from './RootStore';
import TransportLayer from './TransportLayer';
import { Log } from '../models/Log';

export class LogStore {
  transportLayer: TransportLayer;
  @observable logs: Log[] = [];
  rootStore: RootStore;

  constructor(rootStore: RootStore, transportLayer: TransportLayer) {
    makeObservable(this);
    this.transportLayer = transportLayer;
    this.rootStore = rootStore;
  }

  @action clear = () => {
    this.logs = [];
  };

  @action createLog = async (
    pebbleId: string,
    data: {
      message: string | undefined;
      rating: number;
      position: {
        latitude: number;
        longitude: number;
        country: string;
        countryCode: string;
        municipality: string;
        postalCode: string;
        region: string;
        street: string;
        houseNumber: string;
        neighbourhood: string;
        state: string;
        city: string;
      };
    }
  ): Promise<Log> => {
    const res = await this.transportLayer.createLog(pebbleId, data);
    const log = new Log(this);
    log.updateFromServer(res);
    this.logs.push(log);
    return log;
  };

  fetchLogsForPebble = async (pebbleId: string, page: number, pageSize: number): Promise<Log[]> => {
    const res = await this.transportLayer.fetchLogs(pebbleId, page, pageSize);
    return res.map((l: any) => {
      const log = new Log(this);
      log.updateFromServer(l);
      return log;
    }) as Log[];
  };
}
