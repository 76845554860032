import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, makeStyles, withTheme } from '@material-ui/core';
import RootStore from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { Pebble } from '../../models/Pebble';
import { PebbleNotFoundError } from '../../lib/Errors';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

interface IProps {
  rootStore?: RootStore;
  pebbleId?: string;
  code?: string;
  onLoaded?: (pebble: Pebble) => void;
  onNotFound?: () => void;
}

const PebbleLoader = withTheme(
  inject('rootStore')(
    observer((props: React.PropsWithChildren<IProps>) => {
      const classes = useStyles();
      const pebbleStore = props.rootStore!.pebbleStore;
      const [isLoading, setIsLoading] = useState(true);

      useEffect(() => {
        if (props.pebbleId) {
          pebbleStore
            .loadPebbleById(props.pebbleId!)
            .then((pebble: Pebble) => {
              if (pebble && props.onLoaded) {
                props.onLoaded(pebble);
              }
              if (!pebble && props.onNotFound) {
                props.onNotFound();
              }
              setIsLoading(false);
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (props.code) {
          pebbleStore
            .loadPebbleByCode(props.code!)
            .then((pebble: Pebble | undefined) => {
              if (pebble && props.onLoaded) {
                props.onLoaded(pebble);
              }
              setIsLoading(false);
            })
            .catch((err) => {
              console.error('#### err', err);
              if (err instanceof PebbleNotFoundError) {
                setIsLoading(false);
                if (props.onNotFound) props.onNotFound();
              } else {
                throw err;
              }
            });
        }
      }, [props.code]);

      return (
        <>
          {isLoading && (
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {!isLoading && <>{props.children}</>}
        </>
      );
    })
  )
);
export { PebbleLoader };
