import React, { useEffect } from "react";
import { Loader } from "../../components/loaders/Loader";
import RootStore from '../../stores/RootStore';
import { inject } from 'mobx-react';

interface IProps {
  rootStore: RootStore
}

export const SignOut = inject('rootStore')((props: IProps) => {
  
  const signOut = async () => {
    await props.rootStore?.authStore.signOut();
    props.rootStore?.routerStore.push('/');
  }

  useEffect(() => {
    signOut();
  }, [])
  
  return (
    <Loader withBackdrop={true} />
  )
})
