import { Box, Button, Grid, Link, makeStyles, TextField, Typography, withTheme } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { CustContainer } from '../../components/customizations/CustContainer';
import { InfoStep } from '../../components/InfoStep';
import RootStore from '../../stores/RootStore';
import { LastFoundPebbles } from './components/LastFoundPebbles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white'
  },
  homeSection: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10)
  },
  imageSection: {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    color: 'white',
    fontSize: '1.5rem'
  },
  codeInput: {
    fontSize: '2rem',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    fontFamily: 'consolas',
    fontWeight: 200,
    letterSpacing: '5px'
  },
  searchButton: {
    fontSize: '1rem',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  startNowButton: {},
  paragraph: {
    marginBottom: theme.spacing(2)
  }
}));

interface IProps {
  rootStore?: RootStore;
}

const Home = withTheme(
  inject('rootStore')(
    observer((props: React.PropsWithChildren<IProps>) => {
      const classes = useStyles();
      const [code, setCode] = useState<string>();
      const [codeValid, setCodeValid] = useState<boolean>();
      const image = {
        shadeOverlay: 0.1,
        src: 'content/assets/home-background2.webp',
        // authorName: 'Pixabay',
        // authorUrl: 'https://www.pexels.com/@pixabay',
        // photoSiteName: 'pexels',
        // photoSiteUrl: 'https://www.pexels.com/photo/sea-mountains-sky-beach-65614/'
      };

      const onCodeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value.toUpperCase());
        setCodeValid(e.target.value !== undefined && e.target.value.length >= 1);
      };
      const onCodeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) onLookupClick();
      };

      const onLookupClick = () => {
        if (codeValid === undefined) {
          setCodeValid(false);
        } else {
          props.rootStore!.routerStore.push(`/lookup/${code}`);
        }
      };
      const onRegisterPebbleClick = () => {
        props.rootStore!.routerStore.push('/get-started');
      };

      return (
        <>
          <Box
            className={`${classes.homeSection} ${classes.imageSection}`}
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, ${image.shadeOverlay}), rgba(0, 0, 0, ${image.shadeOverlay})), url(${image.src})`
            }}
          >
            <CustContainer>
              <Box display="flex" flexDirection="row" justifyContent="center" mb={2} flexWrap="wrap">
                <Box p={1}>
                  <Typography variant="h3" component="span">
                    Steen gevonden?
                  </Typography>
                </Box>
                <Box p={1}>
                  <Typography variant="h3" component="span">
                    Voer de code in
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" height={300} justifyContent="center" flexWrap="wrap">
                <Box flexBasis="30%" minWidth={200}>
                  <TextField
                    InputProps={{
                      className: classes.codeInput
                    }}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    onKeyDown={onCodeKeyDown}
                    value={code}
                    error={codeValid !== undefined && !codeValid}
                    onChange={onCodeChanged}
                  />
                </Box>
                <Box ml={2}>
                  <Button variant="contained" color="primary" size="large" className={classes.searchButton} onClick={onLookupClick}>
                    Verder
                  </Button>
                </Box>
              </Box>
            </CustContainer>
          </Box>
          {/* <Box style={{ marginTop: -55, marginRight: 5 }} display="flex" justifyContent="flex-end">
            <Typography variant="subtitle2" style={{ fontSize: '.7rem' }}>
              Photo by{' '}
              <Link href={image.authorUrl} target="_blank">
                {image.authorName}
              </Link>{' '}
              from{' '}
              <Link href={image.photoSiteUrl} target="_blank">
                {image.photoSiteName}
              </Link>
            </Typography>
          </Box> */}
          <Box className={classes.homeSection}>
            <CustContainer>
              <LastFoundPebbles count={6} />
            </CustContainer>
          </Box>
          <Box className={`section-highlight ${classes.homeSection}`}>
            <CustContainer>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={5}>
                  <Typography variant="h4" component="h1" style={{ marginBottom: '30px' }}>
                    Hoe het werkt
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Je registreert je steen bij Rubble.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Je ontvangt een unieke code voor je steen. De unieke code schilder of schrijf je op de steen.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Een vinder van je steen voert de code in op Rubble. Zo weet jij precies waar je steen gezworven heeft.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <InfoStep index="1">
                        <Typography variant="h5" component="h2" paragraph={true}>
                          Maak een mooie steen.
                        </Typography>
                        <Typography variant="body2">Laat je creativiteit stromen en maak een mooie steen.</Typography>
                      </InfoStep>
                    </Grid>
                    <Grid item xs={12}>
                      <InfoStep index="2">
                        <Typography variant="h5" component="h2" paragraph={true}>
                          Registreer de steen
                        </Typography>
                        <Typography variant="body2">Registreer de steen op Rubble en zet de unieke code op de steen.</Typography>
                      </InfoStep>
                    </Grid>
                    <Grid item xs={12}>
                      <InfoStep index="3">
                        <Typography variant="h5" component="h2" paragraph={true}>
                          Laat de steen zwerven
                        </Typography>
                        <Typography variant="body2">Laat de steen zwerven en voeg optionele aanwijzigingen voor zoekers toe.</Typography>
                      </InfoStep>
                    </Grid>
                    <Grid item xs={12}>
                      <InfoStep index="4">
                        <Typography variant="h5" component="h2" paragraph={true}>
                          Volg de reis van je steen
                        </Typography>
                        <Typography variant="body2">Een vinder van je steen voert de code en aanvullende informatie in op Rubble.</Typography>
                      </InfoStep>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <Button variant="contained" color="primary" size="large" className={classes.startNowButton} onClick={onRegisterPebbleClick}>
                          Registreer mijn steen
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustContainer>
          </Box>

          <Box className={`section ${classes.homeSection}`}>
            <CustContainer>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Typography variant="h4" component="h1" paragraph={true}>
                  Rubble is mogelijk gemaakt door:
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="center" flexWrap="wrap">
                <Box p={2}>
                  <Link href="https://triact.net" target="_blank">
                    <img src="content/assets/triact-logo.png" alt="triact" width="200" />
                  </Link>
                </Box>
                <Box p={2}>
                  <Link href="https://lavart.nl" target="_blank">
                    <img src="content/assets/lavart-logo.png" alt="lavart" width="200" />
                  </Link>
                </Box>
              </Box>
            </CustContainer>
          </Box>
        </>
      );
    })
  )
);

export default Home;
