import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export default class User {
  constructor(id = uuidv4()) {
    makeObservable(this);
    this.id = id;
  }

  @observable id: string = '';
  @observable firstName: string = '';
  @observable lastName: string = '';
  @observable lastNamePrefix: string = '';

  @action updateFromServer = (data: any) => {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastNamePrefix = data.lastNamePrefix;
    this.lastName = data.lastName;
  };
}
