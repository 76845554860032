import { History } from 'history';
import { action, makeObservable, observable } from 'mobx';

export default class RouterStore {
  private history: History | undefined;
  @observable location: Location | undefined;

  constructor() {
    makeObservable(this);
  }

  setHistory = (history: History) => {
    //console.log('router.setHistory', history);
    this.history = history;
  };

  @action updateLocation = (location: Location) => {
    //console.log('router.updateLocation', location, this.history)
    this.location = location;
    if (window) window.scrollTo(0, 0);
  };

  to = (location: string) => this.replaceVariables(location);

  push = (location: string, options?: { return: boolean }) => {
    const loc = options && options.return && location ? `${location}?return=${this.location?.pathname}` : location;
    this.history!.push(this.replaceVariables(loc), null);
  };

  replace = (location: string) => {
    this.history!.replace(location);
  };

  go = (n: number) => {
    this.history!.go(n);
  };

  goBack = () => {
    this.history!.goBack();
  };

  goForward = () => {
    this.history!.goForward();
  };

  search = (q: string) => {
    this.history!.replace({ search: q });
  };

  private replaceVariables = (location: string) => location;

  private scrollToTop() {
    if (window) window.scrollTo(0, 0);
  }
}
