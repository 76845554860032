import React, { useEffect, useState } from 'react';
import RootStore from '../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { LatLngTuple, Map, LatLng, Polyline } from 'leaflet';
import { MapContainer, Popup, TileLayer, Marker } from 'react-leaflet';
import { PebbleMapEntry } from '../models/PebbleMapEntry';
import theme from '../Theme';
import { DateUtils } from '../lib/DateUtils';
import 'leaflet-arrowheads';

// const useStyles = makeStyles((theme) => ({

// }));

interface IProps {
  rootStore?: RootStore;
  pebbleId: string;
}

const PebbleMap = inject('rootStore')(
  observer((props: IProps) => {
    const startPosition: LatLngTuple = [52.2129919, 5.2793703];
    const [map, setMap] = useState<Map>();
    const [lastFoundMarker, setLastFoundMarker] = useState<PebbleMapEntry | undefined>();
    const [markers, setMarkers] = useState<PebbleMapEntry[]>([]);

    useEffect(() => {
      props.rootStore?.pebbleStore.transportLayer.fetchPebbleMap(props.pebbleId).then((entries) => {
        const mapEntries = entries.map((entry) => {
          const mapEntry = new PebbleMapEntry();
          mapEntry.updateFromServer(entry);
          return mapEntry;
        });
        setMarkers(mapEntries);
        setLastFoundMarker(mapEntries.length > 0 ? mapEntries[mapEntries.length - 1] : undefined);
      });
    }, [props.pebbleId, props.rootStore?.pebbleStore.transportLayer]);

    useEffect(() => {
      if (map) {
        const pathCoords = markers.map((marker) => new LatLng(marker.latitude, marker.longitude));
        const line = new Polyline(pathCoords, {
          color: theme.palette.primary.main,
          weight: 2
        });
        (line as any).arrowheads({ size: '12px', yawn: 40 });
        line.addTo(map);
        // markers.forEach(marker => {
        //     new Circle([marker.latitude, marker.longitude], {
        //         color: theme.palette.primary.main,
        //         fillColor: theme.palette.primary.main,
        //         fillOpacity: 0.5,
        //         radius: 5.0
        //     }).addTo(map);
        // })
      }
    }, [map, markers]);

    return (
      <Box style={{ height: '100%', width: '100%' }}>
        <MapContainer center={startPosition} zoom={7} scrollWheelZoom={false} style={{ height: '100%' }} whenCreated={setMap}>
          <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {lastFoundMarker && (
            <Marker key={lastFoundMarker.logId} draggable={false} position={[lastFoundMarker.latitude, lastFoundMarker.longitude]}>
              <Popup>
                <div>Laatste gevonden op {DateUtils.toDateTimeString(lastFoundMarker.timestamp)}</div>
                <div>
                  {lastFoundMarker.street}, {lastFoundMarker.city}
                </div>
              </Popup>
            </Marker>
          )}
          {/* {markers.map((marker, index) => 
                    <Marker 
                        key={marker.logId}
                        draggable={false} 
                        position={[marker.latitude, marker.longitude]}>
                        <Popup>
                            {`${index}: ${marker.city}`}
                        </Popup>
                    </Marker>
                )} */}
          {/* {props.position &&
                    <Marker 
                        draggable={false} 
                        position={[props.position.latitude, props.position.longitude]}>
                        <Popup>
                            {`${props.position.street} ${props.position.houseNumber}, ${props.position.city}`}
                        </Popup>
                    </Marker>
                } */}
        </MapContainer>
      </Box>
    );
  })
);

export { PebbleMap };
