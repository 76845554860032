import React, { useCallback, useEffect, useRef } from 'react';
import { LatLngTuple, Map, Control, LatLngLiteral } from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Box, Button, Typography } from '@material-ui/core';
import { useState } from 'react';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';

export type Position = {
  latitude: number;
  longitude: number;
  country: string;
  countryCode: string;
  municipality: string;
  postalCode: string;
  region: string;
  street: string;
  houseNumber: string;
  neighbourhood: string;
  state: string;
  city: string;
};

interface IProps {
  onChange?: (position: Position) => void;
  position?: Position;
}

const LeafletMapComponent = (props: IProps) => {
  //const [position, setPosition] = useState<LatLngTuple>([52.2129919, 5.2793703]);
  const startPosition: LatLngTuple = [52.2129919, 5.2793703];
  const [map, setMap] = useState<Map>();
  const markerRef = useRef(null);

  const onCurrentLocationClick = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const p: LatLngTuple = [position.coords.latitude, position.coords.longitude];
      map!.flyTo(p, Math.max(map!.getZoom(), 17));
      onChange(
        {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        },
        18
      );
    });
  };

  const onChange = (geolocation: LatLngLiteral, zoomLevel: number) => {
    const Geocoder = (Control as any).Geocoder;
    const geocoder = Geocoder.nominatim({
      reverseQueryParams: {
        'accept-language': 'nl'
      }
    });
    geocoder.reverse(geolocation, zoomLevel, (results: any) => {
      const result = results[0];
      const address = result?.properties?.address;

      if (props.onChange) {
        props.onChange({
          latitude: geolocation.lat,
          longitude: geolocation.lng,
          country: address?.country || '',
          countryCode: address?.country_code || '',
          city: address?.city || address?.town || address?.village || '',
          street: address?.road || '',
          houseNumber: address?.house_number || '',
          municipality: address?.municipality || '',
          neighbourhood: address?.neighbourhood || '',
          region: address?.region || '',
          postalCode: address?.postcode || '',
          state: address?.state || ''
        });
      }
    });
  };

  const onMapClick = useCallback((e) => {
    onChange(e.latlng, 18);
  }, []);

  useEffect(() => {
    if (map) {
      map.on('click', onMapClick);
      return () => {
        map.off('click', onMapClick);
      };
    }
  }, [map, onMapClick]);

  useEffect(() => {
    if (markerRef !== null && markerRef.current !== null) {
      (markerRef.current as any).openPopup();
    }
  }, [props.position]);

  return (
    <Box>
      <Box>
        <Typography>
          Klik op de kaart om de locatie te selecteren of klik op de button 'Selecteer mijn locatie' om je huidige locatie te selecteren. 
        </Typography>
      </Box>
      <Box mt={2}>
        <Button variant="outlined" onClick={onCurrentLocationClick}>
          Selecteer mijn locatie
        </Button>
      </Box>
      <Box style={{ height: '50vh', minHeight: '300px' }} mt={2}>
        <MapContainer center={startPosition} zoom={6} scrollWheelZoom={true} style={{ height: '100%' }} whenCreated={setMap}>
          <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {props.position && (
            <Marker ref={markerRef} draggable={true} position={[props.position.latitude, props.position.longitude]}>
              <Popup>{`${props.position.street} ${props.position.houseNumber}, ${props.position.city}`}</Popup>
            </Marker>
          )}
        </MapContainer>
      </Box>
    </Box>
  );
};

export { LeafletMapComponent };
