import { Box, Button, CircularProgress, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
//import Alert from '@material-ui/lab/Alert';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { CustContainer } from '../../components/customizations/CustContainer';
import passwordValidator from '../../lib/PasswordValidator';
import RootStore from '../../stores/RootStore';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import clsx from 'clsx';
import { CustPaper } from '../../components/customizations/CustPaper';
import { Title } from '../../components/Title';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  resetSection: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10)
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  icon: {
    fontSize: '73px'
  }
}))

interface IProps {
  rootStore?: RootStore;
  passwordResetId: string;
}

type ViewStateType = 'loading' | 'expired' | 'loaded' | 'updating' | 'successful' | 'failed';

interface IFormValues {
  password: string,
  passwordConfirmation: string
}

interface IFormValidations {
  password: boolean | undefined;
  passwordConfirmation: boolean | undefined;
}

interface IProps {
  passwordResetId: string,
  rootStore?: RootStore
}

const PasswordReset = inject('rootStore')(observer((props: IProps) => {
  const classes = useStyles();
  const accountStore = props.rootStore!.accountStore;
  const [state, setState] = useState<ViewStateType>('loading');
  const [attempt, setAttempt] = useState(0);
  const [values, setValues] = useState<IFormValues>({
    password: '',
    passwordConfirmation: ''
  });
  const [validations, setValidations] = useState<IFormValidations>({
    password: undefined,
    passwordConfirmation: undefined
  });

  useEffect(() => {
    (async () => {
      const passwordReset = await accountStore.getPasswordReset(props.passwordResetId);
      setState(passwordReset.expired ? 'expired' : 'loaded');
    })();
  }, [props.passwordResetId])

  useEffect(() => {
    const password = values.password !== undefined && (passwordValidator.validate(values.password) as boolean);
    const passwordConfirmation = values.passwordConfirmation !== undefined && values.password === values.passwordConfirmation;

    setValidations({
      password,
      passwordConfirmation
    });
  }, [values])

  const onChange = (prop: keyof IFormValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onChangePasswordClick = () => {
    setAttempt(attempt + 1);
    if (validations.password && validations.passwordConfirmation) {
      setState('updating');
      accountStore.resetPassword(props.passwordResetId, values.password).then(() => {
        setState('successful');
      }).catch(() => {
        setState('failed');
      });
    }
  };

  // TODO: create reusable loading component
  const Loading = () => (
    <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center">
      <Box color="primary">
        <CircularProgress />
      </Box>
      <Box mt={2}>
        <Typography>Een ogenblik geduld a.u.b...</Typography>
      </Box>
    </Box>
  )

  // TODO: create reusable expired component
  const Expired = () => (
    <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center">
      <Box color="warning.main">
        <AccessAlarmIcon className={clsx(classes.icon)} />
      </Box>
      <Box mt={2}>
        <Typography variant="h4" component="h2">
          Helaas, je wachtwoord reset link is verlopen.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography>Je kunt een nieuwe link aanvragen op het inlog scherm.</Typography>
      </Box>
      <Box mt={2}>
        <Button variant="contained" color="primary" href="/sign-in">
          Inloggen
        </Button>
      </Box>
    </Box>
  );

  // TODO: create reusable updating component
  const Updating = () => (
    <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center">
      <Box color="primary">
        <CircularProgress />
      </Box>
      <Box mt={2}>
        <Typography>Je wachtwoord wordt gewijzigd...</Typography>
      </Box>
    </Box>
  );

  // TODO: create reusable successful component
  const Successful = () => (
    <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center">
      <Box color="success.main">
        <CheckCircleOutlinedIcon className={clsx(classes.icon)} />
      </Box>
      <Box mb={2}>
        <Typography variant="h4" component="h2">
          Je wachtwoord is gewijzigd.
        </Typography>
      </Box>
      <Box mt={2}>
        <Button variant="contained" color="primary" href="/sign-in">
          Inloggen
        </Button>
      </Box>
    </Box>
  );

  // TODO: create reusable failed component
  const Failed = () => (
    <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center">
      <Box color="error.main">
        <ErrorOutlineIcon className={clsx(classes.icon)} />
      </Box>
      <Box mb={2}>
        <Typography variant="h4" component="h2">
          Oops! Er is iets fout gegaan. Probeer het later nog eens.
        </Typography>
      </Box>
      <Box mt={2}>
        <Button variant="contained" color="primary" href="/sign-in">
          Inloggen
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box pt={2} pb={2}>
        <CustContainer maxWidth="sm">
          <CustPaper>
            <Box>
              {state === 'loading' && <Loading />}
              {state === 'loaded' && 
                <form>    
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Title text="Reset Wachtwoord" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="password"
                        label="Wachtwoord"
                        type="password"
                        autoComplete="off"
                        onChange={onChange('password')}
                        className={classes.textField}
                        error={attempt > 0 && validations.password !== undefined && !validations.password}
                        helperText={
                          validations.password !== undefined && !validations.password ? 'Minimaal 8 tekens lang, maximaal 30 tekens lang, tenminste 1 hoofdletter, 1 kleine letter en een cijfer.' : ''
                        }
                        fullWidth
                        variant="filled"
                        required
                      />
                      <TextField
                        id="passwordConfirmation"
                        label="Wachtwoord controle"
                        type="password"
                        autoComplete="off"
                        onChange={onChange('passwordConfirmation')}
                        className={classes.textField}
                        error={attempt > 0 && validations.passwordConfirmation !== undefined && !validations.passwordConfirmation}
                        helperText={
                          validations.passwordConfirmation !== undefined && !validations.passwordConfirmation ? 'De wachtwoorden komen niet overeen' : ''
                        }
                        fullWidth
                        variant="filled"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
                    <Box>
                      <Button variant="contained" color="primary" onClick={onChangePasswordClick}>
                        Wijzig wachtwoord
                      </Button>
                    </Box>
                  </Box>
                </form>
              }
              {state === 'expired' && <Expired />}
              {state === 'updating' && <Updating />}
              {state === 'successful' && <Successful />}
              {state === 'failed' && <Failed />}
            </Box>
          </CustPaper>
        </CustContainer>
    </Box>
  )
}));

export { PasswordReset };
