import React from 'react';
import { Box, Typography, withTheme, Button } from '@material-ui/core';
import { Pebble } from '../../../models/Pebble';
import { inject } from 'mobx-react';
import RootStore from '../../../stores/RootStore';
import { Link } from 'react-router-dom';
import { CustContainer } from '../../../components/customizations/CustContainer';
import { CustPaper } from '../../../components/customizations/CustPaper';

interface IProps {
  rootStore?: RootStore;
  pebble: Pebble;
}

const PebbleFound = withTheme(
  inject('rootStore')((props: IProps) => {

    const StoneDetails = () => (
      <Box display="flex" flexDirection="row">
        <Box flexGrow={1}>
          <img
            src={props.pebble.pictures[0]}
            alt="steen"
            style={{
              maxWidth: '100%'
            }}
          />
        </Box>
      </Box>
    );

    const onNoClick = () => props.rootStore!.routerStore.push('/');

    return (
      <Box pt={2} pb={2}>
        <CustContainer>
          <CustPaper>
            <Box
              style={{
                textAlign: 'center'
              }}
            >
              <Box
                style={{
                  textAlign: 'center'
                }}
              >
                <Typography variant="h3" component="h1">
                  Gefeliciteerd!
                </Typography>
                <Typography>
                  je hebt <strong>{props.pebble.title}</strong> gevonden
                </Typography>
              </Box>
              <Box mt={2}>
                <StoneDetails />
              </Box>
              <Box pt={1}>
                <Typography>{props.pebble.description}</Typography>
              </Box>
              <Box mt={6}>
                <Typography paragraph={true}>De kunstenaar vindt het leuk als je de steen wilt loggen.</Typography>
                <Typography paragraph={true}>We vragen je naar de locatie waar je de steen gevonden hebt, je waardeing voor de steen, en je hebt de mogelijkheid een berichtje achter te laten.</Typography>
                <Typography paragraph={true}>Je hebt hier geen account voor nodig.</Typography>
              </Box>
              <Box mt={2} display="flex" flexDirection="row" justifyContent="center">
                <Box>
                  <Link to={`${props.rootStore?.routerStore.location?.pathname}/log`}>
                    <Button variant="contained" color="primary">
                      Ja, ik wil Loggen
                    </Button>
                  </Link>
                </Box>
                <Box ml={2}>
                  <Button variant="text" onClick={onNoClick}>
                    Nee, ik doe niet mee
                  </Button>
                </Box>
              </Box>
            </Box>
          </CustPaper>
        </CustContainer>
      </Box>
    );
  })
);

export { PebbleFound };
