import { useEffect, useState } from 'react';
import RootStore from '../../stores/RootStore';
import { inject, Observer, observer } from 'mobx-react';
import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core';
import { CustContainer } from '../../components/customizations/CustContainer';
import { CustPaper } from '../../components/customizations/CustPaper';
import { PebbleLogbookWidget } from '../../components/widgets/PebbleLogbookWidget';
import { PebbleWidget } from '../../components/widgets/PebbleWidget';
import { PebbleZwerftochtWidget } from '../../components/widgets/PebbleZwerftochtWidget';

const useStyles = makeStyles((theme) => ({
  newPebble: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  codeTextField: {
    color: 'white'
  },
  codeTextFieldInput: {
    fontSize: '2rem',
    color: 'white',
    borderColor: 'white',
    fontFamily: 'consolas',
    fontWeight: 200,
    letterSpacing: '5px',
    textAlign: 'center'
  }
}));

interface IProps {
  rootStore?: RootStore;
  pebbleId: string;
}

const MyPebble = inject('rootStore')(
  observer((props: IProps) => {
    const classes = useStyles();
    const store = props.rootStore?.pebbleStore;
    const [pebble] = useState(store?.getPebble(props.pebbleId));

    //let pebble: Pebble | undefined = store?.getPebble(props.pebbleId);

    useEffect(() => {
      if (pebble?.code === '') pebble?.fetchCode();
    }, [props.pebbleId]);

    const onGoToHome = () => props.rootStore!.routerStore.push('/my/pebbles');

    const onOpenExample = () => {
      // TODO: show example to write code on pebble.
    };

    const NewPebble = () => (
      <Box mb={2} color="primary">
        <CustPaper className={classes.newPebble}>
          <Typography variant="h4" component="h2">
            Jouw unieke code
          </Typography>
          <Box mt={2}>
            <Typography variant="body2">
              Schrijf de code duidelijk op de achterkant van je steen samen met de een verwijzing naar rubble.app{' '}
              <a href="./#" onClick={onOpenExample}>
                (voorbeeld)
              </a>
            </Typography>
          </Box>
          <Box mt={2}>
            <Observer>
              {() => (
                <TextField
                  variant="outlined"
                  value={pebble?.code}
                  fullWidth
                  className={classes.codeTextField}
                  inputProps={{
                    className: classes.codeTextFieldInput
                  }}
                />
              )}
            </Observer>
          </Box>
        </CustPaper>
      </Box>
    );

    return (
      <Box pt={2} pb={2}>
        <CustContainer>
          <Box mb={2}>
            <PebbleWidget pebble={pebble!} />
          </Box>
          <Box mb={2}>
            <PebbleZwerftochtWidget pebbleId={pebble!.id} />
          </Box>

          <NewPebble />

          <Box mb={2}>
            <PebbleLogbookWidget pebbleId={pebble!.id} />
          </Box>
        </CustContainer>
      </Box>
    );
  })
);

export { MyPebble };
