import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import RootStore from '../../../stores/RootStore';
import { Pebble } from '../../../models/Pebble';
import { PebbleCarousel } from '../../../components/PebbleCarousel';

interface IProps {
  rootStore?: RootStore;
  count: number;
}

const LastFoundPebbles = inject('rootStore')(
  observer((props: IProps) => {
    const [pebbles, setPebbles] = useState<Pebble[]>([]);

    useEffect(() => {
      props.rootStore?.pebbleStore.loadPebblesLastFound(props.count).then((pebbles) => {
        setPebbles(pebbles);
      });
    }, [props.count, props.rootStore?.pebbleStore]);

    return (
      <>
        <Typography variant="h4" component="h1" style={{ marginBottom: '30px' }}>
          Laatste {props.count} gevonden stenen
        </Typography>
        <PebbleCarousel pebbles={pebbles} />
      </>
    );
  })
);

export { LastFoundPebbles };
