import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { Pebble } from '../../../models/Pebble';
import { inject } from 'mobx-react';
import RootStore from '../../../stores/RootStore';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import clsx from 'clsx';
import { PebbleWidget } from '../../../components/widgets/PebbleWidget';
import { PebbleZwerftochtWidget } from '../../../components/widgets/PebbleZwerftochtWidget';
import { PebbleLogbookWidget } from '../../../components/widgets/PebbleLogbookWidget';
import { CustContainer } from '../../../components/customizations/CustContainer';
import { CustPaper } from '../../../components/customizations/CustPaper';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '73px'
  },
  success: {
    color: theme.palette.success.main
  }
}));

interface IProps {
  rootStore?: RootStore;
  pebble: Pebble;
}

export const LogPebbleSuccess = inject('rootStore')((props: IProps) => {
  const classes = useStyles();
  const isAuthenticated = props.rootStore?.authStore.isAuthenticated;
  
  return (
    <CustContainer>
      <CustPaper>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box color="success.main" mb={1}>
            <CheckCircleOutlinedIcon className={clsx(classes.icon)} />
          </Box>
          {!isAuthenticated && 
            <Box>
              <Typography variant="h4" component="h2">
                Bedankt voor het loggen van de steen!
              </Typography>
            </Box>
          }
          {isAuthenticated && 
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box>
                <Typography variant="h4" component="h2">
                  {props.rootStore?.authStore.user?.firstName}, bedankt voor het loggen van de steen!
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography>
                  We hebben de steen toegevoegd aan jouw collectie van gevonden stenen. <Link to={'/my/found-pebbles'}>Bekijk hier je volledige collectie</Link>
                </Typography>
              </Box>
            </Box>
          }
        </Box>
      </CustPaper>
      <Box>
        <Box mt={2}>
          <PebbleWidget pebble={props.pebble} />
        </Box>
        <Box mt={2}>
          <PebbleZwerftochtWidget pebbleId={props.pebble!.id} />
        </Box>
        <Box mt={2} mb={2}>
          <PebbleLogbookWidget pebbleId={props.pebble!.id} />
        </Box>
      </Box>
    </CustContainer>
  );
})
