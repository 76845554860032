import TransportLayer, { SignUpUserData } from './TransportLayer';
import { IPasswordReset } from '../models/IPasswordReset';

export class AccountStore {
  constructor(private readonly transportLayer: TransportLayer) {
    //makeObservable(this);
  }

  async register(user: SignUpUserData): Promise<string> {
    return this.transportLayer.signUp(user);
  }

  async activateAccount(activationId: string): Promise<boolean> {
    return this.transportLayer.activateUser(activationId);
  }

  async getPasswordReset(passwordResetId: string) : Promise<IPasswordReset> {
    return this.transportLayer.getPasswordReset(passwordResetId);
  }

  async resetPassword(passwordResetId: string, password: string) : Promise<void> {
    return this.transportLayer.resetPassword(passwordResetId, password);
  }
}
