import { inject, observer } from 'mobx-react';
import { CustPaper } from '../../components/customizations/CustPaper';
import { Box } from '@material-ui/core';
import RootStore from '../../stores/RootStore';
import { CustContainer } from '../../components/customizations/CustContainer';
import { SignUpForm } from '../../components/SignUpForm';

interface IProps {
  rootStore?: RootStore;
  pebbleId: string;
}

const SignUp = inject('rootStore')(
  observer((props: IProps) => {
    const onSignInClick = () => {
      props.rootStore?.routerStore.push('/sign-in');
    };

    const onSuccessfulRegistration = (userId: string) => {
      props.rootStore?.routerStore.push(`/activate?userId=${userId}`);
    };

    return (
      <Box pt={2} pb={2}>
        <CustContainer>
          <CustPaper>
            <SignUpForm onSuccessful={onSuccessfulRegistration} onSignInClick={onSignInClick} />
          </CustPaper>
        </CustContainer>
      </Box>
    );
  })
);

export { SignUp };
