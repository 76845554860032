import DateFnsUtils from '@date-io/date-fns';
import nlLocale from 'date-fns/locale/nl';
import { DateTime } from 'luxon';

const dateUtils: DateFnsUtils = new DateFnsUtils({ locale: nlLocale });
export default dateUtils;

const toDateString = (date: Date | undefined) => {
  if (!date) return '';
  return dateUtils.format(date!, 'fullDateWithWeekday');
};

const toTimeString = (time: Date | undefined, emptyFormat: string = '__:__') => {
  if (!time || isNaN(time.getTime())) return emptyFormat;

  return dateUtils.format(time!, 'fullTime24h');
};

export class DateUtils {
  static dateOnly = (dateTime: DateTime): DateTime =>
    dateTime.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });

  static toDateString = (value: DateTime | undefined, emptyFormat: string = '...') => {
    if (value && value.isValid) return value.toLocaleString(DateTime.DATE_HUGE);
    return emptyFormat;
  };

  static toTimeString = (value: DateTime | undefined, emptyFormat: string = '__:__') => {
    if (value && value.isValid) return value.toLocaleString(DateTime.TIME_24_SIMPLE);
    return emptyFormat;
  };

  static toDateTimeString = (value: DateTime | undefined, emptyFormat: string = '__:__') => {
    if (value && value.isValid) return value.toLocaleString(DateTime.DATETIME_MED);
    return emptyFormat;
  };
}

export { toDateString, toTimeString };
