import React from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  spinner: {
    color: theme.palette.primary.dark
  }
}));

class IProps {
  open?: boolean = true;
  withBackdrop?: boolean = false;
}

export const Loader = (props: IProps) => {
  const classes = useStyles();

  const Spinner = () => <CircularProgress color="inherit" className={classes.spinner} />

  return (
    <>
      {props.withBackdrop &&
        <Backdrop className={classes.backdrop} open={props.open || true}> 
          <Spinner />
        </Backdrop>
      }

      {!props.withBackdrop && 
        <Spinner />
      }
    </>
  );
};
