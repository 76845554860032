import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '73px'
  },
  success: {
    color: theme.palette.success.main
  }
}));

const PollAccountActivation = (_props: { userId: string }) => {
  const classes = useStyles();

  // const pollVerification = () => {
  // }

  return (
    <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center">
      <Box>
        <AccountCircleOutlinedIcon className={classes.icon} />
      </Box>
      <Box mb={2}>
        <Typography variant="h4" component="h2">
          Nog maar een stap - Verifieer je email adres.
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">We hebben een activatie link gestuurd naar je email adres.</Typography>
      </Box>
    </Box>
  );
};

export { PollAccountActivation };
