import React, { useEffect, useState } from 'react';
import RootStore from '../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { Box, Typography } from '@material-ui/core';
import { Log } from '../models/Log';
import { DateUtils } from '../lib/DateUtils';
import Button from '@material-ui/core/Button';

interface IProps {
  rootStore?: RootStore;
  pebbleId: string;
}

const PebbleLogbook = inject('rootStore')(
  observer((props: IProps) => {
    const pageSize = 10;
    const store = props.rootStore?.logStore;
    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState<Log[]>([]);
    const [allLogsLoaded, setAllLogsLoaded] = useState(false);

    useEffect(() => {
      store?.fetchLogsForPebble(props.pebbleId, page, pageSize).then((fetchedLogs) => {
        setLogs((l) => l.concat(fetchedLogs));
        if (fetchedLogs.length < pageSize) {
          setAllLogsLoaded(true);
        }
      });
    }, [page, props.pebbleId, store]);

    const onLoadLogsClick = () => {
      setPage(page + 1);
    };

    const LogbookEntry = (props: { log: Log }) => (
      <Box mb={2}>
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between" mb={1}>
          <Box flexGrow={1} display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="body2" paragraph={false}>
                <strong>
                  {props.log.location.street}, {props.log.location.city}, {props.log.location.country}
                </strong>
              </Typography>
              <Typography variant="body2" paragraph={false}>
                {DateUtils.toDateTimeString(props.log.timestamp)}
              </Typography>
            </Box>
          </Box>
          <Box mr={1}>
            <Typography variant="body2">{props.log.username}</Typography>
          </Box>
        </Box>
        <Box mb={1}>
          <Typography variant="body2">{props.log.message}</Typography>
        </Box>
      </Box>
    );

    return (
      <Box>
        {logs.map((log) => (
          <LogbookEntry key={log.id} log={log} />
        ))}
        <Box>
          {!allLogsLoaded && (
            <Button onClick={onLoadLogsClick} color="primary">
              Nog meer logs weergegeven
            </Button>
          )}
        </Box>
      </Box>
    );
  })
);

export { PebbleLogbook };
