import { DateTime } from 'luxon';
import { observable, action, computed, makeObservable } from 'mobx';
import { PebbleStore } from '../stores/PebbleStore';

export type LastFoundLocation = {
  latitude: number;
  longitude: number;
  description: string;
};

export class Pebble {
  private store: PebbleStore;

  @observable id: string = '';
  @observable ownerUserId: string = '';
  @observable pictures: string[] = [];
  @observable title: string = '';
  @observable description: string = '';
  @observable rating: number = 0;
  @observable foundCount: number = 0;
  @observable createTimestamp: DateTime = DateTime.now();
  @observable code: string = '';
  @observable lastFoundTimestamp: DateTime | undefined;
  @observable lastFoundLocation: LastFoundLocation | undefined;

  constructor(store: PebbleStore) {
    makeObservable(this);
    this.store = store;
  }

  @computed get mainPicture() {
    return this.pictures.length >= 1 ? this.pictures[0] : '';
  }

  @action setTitle = (title: string) => (this.title = title);
  @action setDescription = (description: string) => (this.description = description);
  @action uploadPicture = async (file: File) => {
    const url = await this.store.transportLayer.uploadPicture(this.id!, file);
    this.pictures.push(url);
  };
  @action fetchCode = async () => {
    try {
      this.code = await this.store.transportLayer.fetchPebbleCode(this.id!);
    } catch (err) {
      this.code = '';
    }
  };
  @action updateFromServer = (data: any) => {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.ownerUserId = data.ownerUserId;
    this.pictures = data.pictures ?? [];
    this.rating = data.stats?.rating ?? 0;
    this.foundCount = data.stats?.logCount ?? 0;
    this.createTimestamp = DateTime.fromISO(data.createTimestamp);
    this.lastFoundTimestamp = DateTime.fromISO(data.stats?.lastFoundTimestamp);

    const location = data.stats?.lastFoundLocation;
    if (location) {
      this.lastFoundLocation = {
        latitude: location.latitude,
        longitude: location.longitude,
        description: location.description
      };
    }
  };
}
