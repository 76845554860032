import cyan from '@material-ui/core/colors/cyan';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// Color palette: #30 close to nature (https://visme.co/blog/website-color-schemes/)
// First color schema
const colorPrimaryDark = '#385D73'; // (blue)
const colorPrimaryMain = '#5085A5'; // (blue)
const colorPrimaryLight = '#96B6C9'; // (blue)
const colorPrimaryContastText = '#FFFFFF'; // white
//const colorSecondaryDark = '#687864'; // todo
const colorSecondaryMain = '#687864';
//const colorSecondaryLight = '#687864'; // todo
const colorSecondaryContastText = '#FFFFFF';
const colorBackground = '#F7F9FB'; // (white)

// Create a theme instance.
const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: 'Roboto, sans-serif'
    },
    palette: {
      primary: {
        dark: colorPrimaryDark,
        main: colorPrimaryMain,
        light: colorPrimaryLight,
        contrastText: colorPrimaryContastText
      },
      secondary: {
        //dark: colorSecondaryDark,
        main: colorSecondaryMain,
        //light: colorSecondaryLight,
        contrastText: colorSecondaryContastText
      },
      // error: {
      // 	main: red.A400,
      // },
      background: {
        default: colorBackground
      },
      info: {
        main: cyan[700]
      }
    },
    overrides: {
      MuiContainer: {
        root: {
          maxWidth: 'xs',
          disableGutters: true
        }
      },
      MuiPaper: {}
    }
  })
);

export default theme;
