import React from "react";
import { Box, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, Radio, RadioGroup, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export type AuthMode = 'signUp' | 'signIn' | 'anonymous' | '';

interface IProps {
  value: AuthMode,
  anonymousEnabled?: boolean,
  onChange: (value: AuthMode) => void,
  error?: boolean
}

export const AuthModeSelectionPartial = (props: IProps) => {

  const anonymousEnabled = props.anonymousEnabled || false;
  const error = props.error !== undefined ? props.error : false;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value as AuthMode);
  }

  return (
    <Box>
        <RadioGroup aria-label="authentication-type" name="authentication-type" value={props.value} onChange={onChange}>
          <FormControlLabel value="signIn" control={<Radio />} label="Ik heb al een account" />
          <FormControlLabel value="signUp" control={<Radio />} label="Ik ben nieuw en wil een account aanmaken" />
          {anonymousEnabled &&
            <FormControlLabel value="anonymous" control={<Radio />} label="Ik wil graag anoniem loggen" />
          }
        </RadioGroup>
        {error &&
          <Box mt={2}>
            <Alert severity="error">Maak een keuze uit één van de opties.</Alert>
          </Box>
        }
      </Box>
  )
}
