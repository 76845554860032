import { DateTime } from 'luxon';
import { observable, action, makeObservable } from 'mobx';
import { LogStore } from '../stores/LogStore';

export type LogLocation = {
  latitude: number;
  longitude: number;
  street: string;
  city: string;
  country: string;
};

export class Log {
  private store: LogStore;

  @observable id: string = '';
  @observable userId: string = '';
  @observable username: string = '';
  @observable message: string = '';
  @observable rating: number | undefined;
  @observable location: LogLocation = {
    longitude: 0,
    latitude: 0,
    street: '',
    city: '',
    country: ''
  };
  @observable timestamp: DateTime = DateTime.now();

  constructor(store: LogStore) {
    makeObservable(this);
    this.store = store;
  }

  @action updateFromServer = (data: any) => {
    this.id = data.id;
    this.userId = data.userId;
    this.username = data.username ?? 'anoniem';
    this.message = data.message;
    this.location = data.position;
    this.rating = data.rating;
    this.location = {
      latitude: data.location.latitude,
      longitude: data.location.longitude,
      street: data.location.street,
      city: data.location.city,
      country: data.location.country
    };
    this.timestamp = DateTime.fromISO(data.timestamp);
  };
}
