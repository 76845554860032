import { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { CustPaper } from '../../components/customizations/CustPaper';
import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import RootStore from '../../stores/RootStore';
import { CustContainer } from '../../components/customizations/CustContainer';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { useQuery } from '../../lib/hooks/useQuery';
import clsx from 'clsx';
import { PollAccountActivation } from '../../components/PollAccountActivation';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '73px'
  },
  success: {
    color: theme.palette.success.main
  }
}));

const AccountActivation = inject('rootStore')(
  observer(() => {
    const query = useQuery();
    const state: 'activate' | 'poll-activation' | 'unknown' = getState();

    function getState() {
      if (query.has('id')) return 'activate';
      if (query.has('userId')) return 'poll-activation';
      return 'unknown';
    }

    return (
      <Box pt={2} pb={2}>
        <CustContainer>
          <CustPaper>
            {state === 'activate' && <ActivateAccount activationId={String(query.get('id'))} />}
            {state === 'poll-activation' && <PollAccountActivation userId={String(query.get('userId'))} />}
          </CustPaper>
        </CustContainer>
      </Box>
    );
  })
);

export { AccountActivation };

type AccountActivationState = 'activated' | 'activating' | 'failed';

const ActivateAccount = inject('rootStore')((props: { rootStore?: RootStore; activationId: string }) => {
  const classes = useStyles();
  const [state, setState] = useState<AccountActivationState>('activating');

  const activateAccount = useCallback(async () => {
    if (props.activationId) {
      const result = await props.rootStore?.accountStore.activateAccount(props.activationId);
      if (result) {
        setState('activated');
      } else {
        setState('failed');
      }
    }
  }, [props.activationId, props.rootStore?.accountStore]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await activateAccount();
    })();
  }, []);

  return (
    <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center">
      {state === 'activating' && (
        <>
          <Box color="primary">
            <CircularProgress />
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle2">We zijn je account aan het activeren. Een ogenblik geduld a.u.b...</Typography>
          </Box>
        </>
      )}
      {state === 'activated' && (
        <>
          <Box color="success.main">
            <CheckCircleOutlinedIcon className={clsx(classes.icon)} />
          </Box>
          <Box mb={2}>
            <Typography variant="h4" component="h2">
              Je account is succesvol geactiveerd
            </Typography>
          </Box>
          <Box mt={2}>
            <Button variant="contained" color="primary" href="/sign-in">
              Inloggen
            </Button>
          </Box>
        </>
      )}
      {state === 'failed' && (
        <>
          <Box color="warning.main">
            <AccessAlarmIcon className={clsx(classes.icon)} />
          </Box>
          <Box mt={2}>
            <Typography variant="h4" component="h2">
              Helaas, je activatie link is verlopen.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography>Log opnieuw in en we verzenden direct een nieuwe activatie link naar je email adres.</Typography>
          </Box>
          <Box mt={2}>
            <Button variant="contained" color="primary" href="/sign-in">
              Inloggen
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
});
