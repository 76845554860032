import { useEffect, useState } from 'react';
import RootStore from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { Loading } from '../../components/Loading';
import { CustContainer } from '../../components/customizations/CustContainer';
import { ProfileFoundPebble } from '../../models/ProfileFoundPebble';
import { DateUtils } from '../../lib/DateUtils';

interface IProps {
  rootStore?: RootStore;
}

const MyFoundPebbles = inject('rootStore')(
  observer((props: IProps) => {
    const store = props.rootStore?.profileStore;
    const [pebblesLoading, setPebblesLoading] = useState<boolean>(true);
    const [foundPebbles, setFoundPebbles] = useState<ProfileFoundPebble[]>([]);

    useEffect(() => {
      const userId = props.rootStore?.authStore.user?.id || '';
      store?.fetchFoundPebblesForUser(userId, 1, 50).then((pebbles) => {
        setPebblesLoading(false);
        setFoundPebbles(pebbles.sort((a, b) => b.lastFoundTimestamp.toMillis() - a.lastFoundTimestamp.toMillis()));
      });
    }, [store, props.rootStore?.authStore.user?.id]);

    const FoundPebbleTile = (props: { foundPebble: ProfileFoundPebble }) => {
      const onTitleClick = () => {
        //TODO: implement on title click in MyFoundPebbles
      };
      const onImageClick = () => {
        //TODO: implement on image click in MyFoundPebbles
      };

      const LastFoundInfo = () => (
        <Box display="flex" flexDirection="column">
          <Box>
            <Typography variant="caption">
              <strong>Laatst gevonden</strong>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <Typography variant="body2">{DateUtils.toDateTimeString(props.foundPebble.lastFoundTimestamp)}</Typography>
              <Typography variant="body2">
                {props.foundPebble.lastFoundLocation?.street}, {props.foundPebble.lastFoundLocation.city}
              </Typography>
            </Box>
          </Box>
        </Box>
      );

      return (
        <Paper square={true} elevation={1}>
          <Box p={2}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
              <Box>
                <Typography gutterBottom variant="h5" component="h2" onClick={onTitleClick} className="pointer">
                  {props.foundPebble.pebble?.title}
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <img onClick={onImageClick} src={props.foundPebble.pebble?.mainPicture} alt={props.foundPebble.pebble?.title} style={{ width: '100%' }} className="pointer" />
              </Grid>
              <Grid item xs={12} sm={8}>
                <LastFoundInfo />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      );
    };

    return (
      <Box mt={2} mb={2}>
        <CustContainer>
          <Box p={2}>
            <Typography variant="h3" component="h1">
              Mijn gevonden stenen
            </Typography>
          </Box>
          <Loading loading={pebblesLoading} text="Stenen aan het verzamelen..." />
          <Box>
            {foundPebbles.map((foundPebble) => (
              <Box key={foundPebble.id} mb={2}>
                <FoundPebbleTile foundPebble={foundPebble} />
              </Box>
            ))}
          </Box>
        </CustContainer>
      </Box>
    );
  })
);

export { MyFoundPebbles };
