import { Box, withTheme } from '@material-ui/core';
import { Pebble } from '../../../models/Pebble';
import { CustContainer } from '../../../components/customizations/CustContainer';
import { PebbleWidget } from '../../../components/widgets/PebbleWidget';
import { PebbleZwerftochtWidget } from '../../../components/widgets/PebbleZwerftochtWidget';
import { PebbleLogbookWidget } from '../../../components/widgets/PebbleLogbookWidget';
import RootStore from '../../../stores/RootStore';
import { inject } from 'mobx-react';

interface IProps {
  rootStore?: RootStore;
  pebble: Pebble;
}

const PebbleDetails = inject('rootStore')(
  withTheme((props: IProps) => (
    <Box pt={2} pb={2}>
      <CustContainer>
        <Box mb={2}>
          <PebbleWidget pebble={props.pebble} />
        </Box>
        <Box mb={2}>
          <PebbleZwerftochtWidget pebbleId={props.pebble!.id} />
        </Box>
        <Box mb={2}>
          <PebbleLogbookWidget pebbleId={props.pebble!.id} />
        </Box>

        {/* <StoneDetails />
            <ArtistInfo /> */}
      </CustContainer>
    </Box>
  ))
);

export { PebbleDetails };
