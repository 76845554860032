import * as jwt from 'jsonwebtoken';
import { action, computed, makeObservable, observable } from 'mobx';
import axios from '../lib/Axios';
import User from '../models/User';
import RootStore from './RootStore';
import TransportLayer from './TransportLayer';
import { UserNotActivatedError } from '../lib/Errors';
import { AxiosRequestConfig } from 'axios';

export type SignInResult = 'success' | 'failed' | 'user-not-activated';

export default class AuthStore {
  private rootStore: RootStore;
  transportLayer: TransportLayer;
  @observable user: User | undefined;
  @observable private token: string = '';
  private tokenExpirationAt: number = 0;
  private tokenIssuedAt: number = 0;
  private refreshTimeout: NodeJS.Timeout | null = null;

  @computed get isAuthenticated() {
    return this.token !== '' && this.user !== undefined;
  }

  constructor(rootStore: RootStore, transportLayer: TransportLayer) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.transportLayer = transportLayer;
    this.addAxiosAuthenticationMiddleware();
  }

  async initFromLocalStorage() {
    await this.loadFromLocalStorage();
  }

  @action signIn = async (username: string, password: string): Promise<SignInResult> => {
    try {
      const data = await this.transportLayer.signIn(username, password);
      await this.updateFromToken(data.token);
      this.updateLocalStorage();
      return 'success';
    } catch (err) {
      console.error('Authentication failed', err);
      if (err instanceof UserNotActivatedError) {
        return 'user-not-activated';
      }
      return 'failed';
    }
  };

  @action signOut = async (): Promise<void> => {
    //setTimeout(() => {
    this.token = '';
    this.user = undefined;
    localStorage.clear();
    this.rootStore.clearAllStores();
    //}, 500);
  };

  private updateLocalStorage = () => {
    if (this.token) {
      localStorage.setItem('auth.token', this.token);
    } else {
      localStorage.removeItem('auth.token');
    }
  };

  private scheduleTokenRefresh = async () => {
    const now = new Date().getTime();
    const interval = this.tokenExpirationAt * 1000 - now - 60 * 1000; // 1 minute before expiry

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.refreshTimeout = setTimeout(this.refreshToken, interval);
  };

  private refreshToken = async () => {
    try {
      const data = await this.transportLayer.renewToken();
      await this.updateFromToken(data.token);
      this.updateLocalStorage();
    } catch (err) {
      console.error(err);
      await this.signOut();
    }
  }

  @action private updateFromToken = async (token: string) => {
    const decodedToken: any = jwt.decode(token, { complete: true });
    if (new Date().getTime() - 60 * 1000 > decodedToken.payload.exp * 1000) {
      await this.signOut();
    }
    const user = new User();
    user.updateFromServer(decodedToken.payload.data.user);
    this.token = token;
    this.tokenExpirationAt = decodedToken.payload.exp;
    this.tokenIssuedAt = decodedToken.payload.iat;
    this.user = user;
    await this.scheduleTokenRefresh();
  };

  @action private loadFromLocalStorage = async () => {
    const token = localStorage.getItem('auth.token') as string;
    if (token) {
      await this.updateFromToken(token);
      this.token = token;
    }
  };

  private addAxiosAuthenticationMiddleware = () => {
    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      if (this.token) {

        if (!config.headers) config.headers = {};

        config.headers['Authorization'] = `bearer ${this.token}`;

        
      }
      return config;
    });
  };
}
