import { observer } from 'mobx-react';
import { Pebble } from '../../models/Pebble';
import { CustPaper } from '../customizations/CustPaper';
import { Box, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { DateUtils } from '../../lib/DateUtils';

interface IProps {
  pebble: Pebble;
}

const PebbleWidget = observer((props: IProps) => {
  const Title = () => (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start" mb={2} flexWrap="wrap">
      <Box>
        <Typography variant="h3" component="h1">
          {props.pebble?.title}
        </Typography>
      </Box>
      <Box ml={2}>
        <Rating precision={0.5} value={props.pebble?.rating || 0} readOnly />
      </Box>
    </Box>
  );

  const Picture = () => (
    <Box display="flex" flexDirection="row" justifyContent="space-around">
      <Box flexGrow={0}>
        <img
          src={props.pebble?.pictures[0]}
          alt="steen"
          style={{
            maxWidth: '100%'
          }}
        />
      </Box>
    </Box>
  );

  const Info = () => (
    <>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box flexBasis="50%">
          <FoundCount />
        </Box>
        <Box flexBasis="50%">
          <LastFoundInfo />
        </Box>
      </Box>
      {/* <IconInfo /> */}
    </>
  );

  const FoundCount = () => (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="caption">
          <strong>Gevonden</strong>
        </Typography>
      </Box>
      <Box>
        <Typography variant="h4" component="span">
          {props.pebble?.foundCount ?? ''}
        </Typography>
      </Box>
    </Box>
  );

  const LastFoundInfo = () => (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="caption">
          <strong>Laatst gevonden</strong>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <Typography variant="body2">{DateUtils.toDateTimeString(props.pebble?.lastFoundTimestamp)}</Typography>
          <Typography variant="body2">{props.pebble?.lastFoundLocation?.description}</Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <CustPaper>
      <Box mb={2}>
        <Title />
        <Typography>{props.pebble?.description}</Typography>
      </Box>
      <Box>
        <Picture />
      </Box>
      <Info />
    </CustPaper>
  );
});

export { PebbleWidget };
