import { inject, observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthenticatedArea } from '../../components/AuthenticatedArea';
import { PebbleLoader } from '../../components/loaders/PebbleLoader';
import { MyFoundPebbles } from './MyFoundPebbles';
import { MyPebbles } from './MyPebbles';
import { MyPebble } from './MyPebble';

const MyIndex = inject('rootStore')(
  observer(() => (
    <>
      <AuthenticatedArea>
        <Switch>
          <Route exact={true} path="/my" render={() => <Redirect to="/my/pebbles" />} />
          <Route exact={true} path="/my/found-pebbles" component={MyFoundPebbles} />
          <Route exact={true} path="/my/pebbles" component={MyPebbles} />
          <Route
            exact={false}
            path="/my/pebbles/:pebbleId"
            render={(props) => (
              <PebbleLoader pebbleId={props.match.params.pebbleId}>
                <MyPebble {...props} pebbleId={props.match.params.pebbleId} />
              </PebbleLoader>
            )}
          />
        </Switch>
      </AuthenticatedArea>
    </>
  ))
);

export { MyIndex };
