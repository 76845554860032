import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTheme, withTheme } from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, IconButton, makeStyles, MobileStepper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { PageTitle } from '../PageTitle';

const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: 'transparent'
  }
}));

interface IStepFormStep {
  component: React.ReactNode,
  extraButtonComponent?: React.ReactNode,
  previousButtonText?: string,
  nextButtonText?: string,
  alternatePreviousAction?: () => void,
  alternateNextAction?: () => void,
  onNext?: () => Promise<boolean>,
  skip?: boolean,
  title?: string
}

interface IProps {
  title?: string;
  finishButtonText?: string;
  finishButtonDisabled?: boolean;
  onFinish?: () => void;
  //onValidateStep?: (index: number) => boolean;
  onActiveStepChange?: (index: number) => void;
  steps: IStepFormStep[]
}

export const StepForm = observer((props: React.PropsWithChildren<IProps>) => {
  const classes = useStyles();
  const [steps, setSteps] = useState<IStepFormStep[]>([]);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  
  const handleBack = () => {
    if (activeStep?.alternatePreviousAction) {
      activeStep?.alternatePreviousAction();
    } else {
      setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNext = async () => {
    if (activeStep?.alternateNextAction) {
      activeStep?.alternateNextAction();
    } else if (!activeStep.onNext || await activeStep.onNext()) {
      setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
    }
    //if (!props.onValidateStep || props.onValidateStep(activeStepIndex)) setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    const nonSkippedSteps = props.steps.filter(s => s.skip === undefined || !s.skip);
    setSteps(nonSkippedSteps);    
  }, [props.steps])

  useEffect(() => {
    if (props.onActiveStepChange) props.onActiveStepChange(activeStepIndex);
  }, [activeStepIndex]);

  const handleSubmit = async () => {
    (!activeStep.onNext || await activeStep.onNext()) && props.onFinish && props.onFinish();
    //(!props.onValidateStep || props.onValidateStep(activeStepIndex)) && props.onSubmit && props.onSubmit();
  };

  //const stepCount = React.Children.toArray(props.children).length;
  const stepCount = steps.length;
  const activeStep = steps[activeStepIndex];
  const title = activeStep?.title || props.title;

  return (
    <>
      <Box>
        {title && <PageTitle text={title} />}
        <Box>
          {
            activeStep?.component
          }
          {/* { React.Children.map(props.children, (child, index) => (
            <>
              { index === activeStepIndex && child }
            </>
          )) } */}
        </Box>
        <Box mt={2}>
          <Box flexGrow={1} mr={-1} ml={-1} mb={-1}>
            <MobileStepper
              steps={stepCount}
              position="static"
              variant="progress"
              activeStep={activeStepIndex}
              className={classes.stepper}
              backButton={
                <Box mr={2}>
                  <Button size="small" onClick={handleBack} variant="outlined" disabled={activeStepIndex === 0}>
                    <KeyboardArrowLeft />
                    {activeStep?.previousButtonText || 'Vorige'}
                  </Button>
                </Box>
              }
              nextButton={
                <Box ml={2}>
                  {activeStepIndex < stepCount - 1 && (
                    <Box display="flex" flexDirection="row">
                      {activeStep?.extraButtonComponent !== undefined &&
                        <Box mr={2}>
                          {activeStep?.extraButtonComponent}
                        </Box>
                      }
                      <Button size="small" onClick={handleNext} variant="contained" color="primary">
                        {
                          activeStep?.nextButtonText || 'Volgende'
                        }                      
                        <KeyboardArrowRight />
                      </Button>
                    </Box>
                  )}
                  {activeStepIndex === stepCount - 1 && (
                    <Box>
                      <Button size="small" onClick={handleSubmit} variant="contained" color="primary" disabled={props.finishButtonDisabled !== undefined && props.finishButtonDisabled}>
                        {
                          activeStep?.nextButtonText || props.finishButtonText || 'Voltooien'
                        }
                      </Button>
                    </Box>
                  )}
                </Box>
              }              
            />
          </Box>
        </Box>
      </Box>
    </>
  );
});
