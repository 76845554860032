import { DateTime } from 'luxon';
import { observable, action, makeObservable } from 'mobx';

export class PebbleMapEntry {
  @observable logId: string = '';
  @observable latitude: number = 0;
  @observable longitude: number = 0;
  @observable street: string = '';
  @observable city: string = '';
  @observable timestamp: DateTime | undefined;

  constructor() {
    makeObservable(this);
  }

  @action updateFromServer = (data: any) => {
    this.logId = data.logId;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.street = data.street;
    this.city = data.city;
    this.timestamp = DateTime.fromISO(data.timestamp);
  };
}
