import { Box, Typography } from "@material-ui/core";
import React from "react";

interface IProps {
  text: string
}

export const PageTitle = (props: IProps) => (
  <Box mb={2}>
    <Typography variant="h4" component="h2">
      {props.text}
    </Typography>
  </Box>
)
