import React, { useEffect, useState } from 'react';
import RootStore from '../../stores/RootStore';
import { inject, observer } from 'mobx-react';
import { Box, Typography } from '@material-ui/core';
import { Loading } from '../../components/Loading';
import { MyPebbleRow } from './components/MyPebbleRow';
import { CustContainer } from '../../components/customizations/CustContainer';
import { Pebble } from '../../models/Pebble';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         [theme.breakpoints.down('sm')]: {
//         }
//     }
// }));

interface IProps {
  rootStore?: RootStore;
}

const MyPebbles = inject('rootStore')(
  observer((props: IProps) => {
    const store = props.rootStore?.pebbleStore;
    const [pebblesLoading, setPebblesLoading] = useState<boolean>(true);
    const [pebbles, setPebbles] = useState<Pebble[]>([]);

    useEffect(() => {
      store?.loadMyPebbles().then(() => {
        setPebblesLoading(false);
        setPebbles(store?.myPebbles.sort((a, b) => b.foundCount - a.foundCount));
      });
    }, [store]);

    //.sort((a, b) => b.createTimestamp.toMillis() - a.createTimestamp.toMillis())

    return (
      <Box mt={2} mb={2}>
        <CustContainer>
          <Box p={2}>
            <Typography variant="h3" component="h1">
              Mijn stenen
            </Typography>
          </Box>
          <Loading loading={pebblesLoading} text="Stenen aan het verzamelen..." />
          <Box>
            {pebbles.map((pebble) => (
              <Box key={pebble.id} mb={2}>
                <MyPebbleRow pebble={pebble} />
              </Box>
            ))}
          </Box>
        </CustContainer>
      </Box>
    );
  })
);

export { MyPebbles };
