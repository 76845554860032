import { DateTime } from 'luxon';
import { action, makeObservable, observable } from 'mobx';
import { ProfileStore } from '../stores/ProfileStore';
import { Pebble } from './Pebble';

export class ProfileFoundPebbleLocation {
  @observable latitude: number = 0;
  @observable longitude: number = 0;
  @observable street: string = '';
  @observable city: string = '';
}

export class ProfileFoundPebble {
  store: ProfileStore;

  @observable id: string = '';
  @observable userId: string = '';
  @observable pebbleId: string = '';
  @observable lastFoundTimestamp: DateTime = DateTime.now();
  @observable lastFoundLocation: ProfileFoundPebbleLocation = new ProfileFoundPebbleLocation();
  @observable pebble: Pebble | undefined;

  constructor(store: ProfileStore) {
    makeObservable(this);
    this.store = store;
  }

  @action setPebble = (pebble: Pebble) => {
    this.pebble = pebble;
  };

  @action updateFromServer = (data: any) => {
    this.id = data.id;
    this.userId = data.userId;
    this.pebbleId = data.pebbleId;
    this.lastFoundTimestamp = DateTime.fromISO(data.lastFoundTimestamp);
    this.lastFoundLocation.latitude = data.lastFoundLocation.latitude;
    this.lastFoundLocation.longitude = data.lastFoundLocation.longitude;
    this.lastFoundLocation.street = data.lastFoundLocation.street;
    this.lastFoundLocation.city = data.lastFoundLocation.city;
  };
}
