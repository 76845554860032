import React from 'react';
import { inject, observer } from 'mobx-react';
import { Avatar, Box, Divider, Drawer, IconButton, makeStyles, MenuItem, Typography } from '@material-ui/core';
import RootStore from '../stores/RootStore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MapIcon from '@material-ui/icons/Map';
import BrushIcon from '@material-ui/icons/Brush';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddIcon from '@material-ui/icons/Add';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    width: drawerWidth,
    //zIndex: theme.zIndex.appBar - 1,
    '& .MuiDrawer-paper': {
      zIndex: theme.zIndex.appBar - 1
    }
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    '& button': {
      color: theme.palette.background.default,
    }
  },
  drawerContainer: {
    overflow: 'auto',
    width: drawerWidth
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  menuItemText: {
    marginLeft: theme.spacing(1)
  }
}));

interface IProps {
  rootStore?: RootStore;
  open?: boolean;
  sticky?: boolean;
  onClose?(): void;
}

export const MainMenu = inject('rootStore')(observer((props: IProps) => {
  const classes = useStyles();
  const isAuthenticated = props.rootStore?.authStore.isAuthenticated;
  const firstName = isAuthenticated ? props.rootStore?.authStore.user?.firstName : '';

  const close = () => {
    if (!props.sticky && props.onClose) props.onClose();
  };
  const onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    close();
  };
  const navigate = (path: string) => () => {
    props.rootStore!.routerStore.push(path);
    close();
  }

  const handleDrawerClose = () => {
    if (props.onClose) props.onClose();
  };

  const MyMenuItem = (myProps: {
    title: string,
    icon: React.ReactNode,
    url: string
  }) => (
    <MenuItem onClick={navigate(myProps.url)}>
      {myProps.icon}
      <Typography className={classes.menuItemText}>{myProps.title}</Typography>
    </MenuItem>
  );

  return (
    <Drawer open={props.open} onClose={onClose} anchor="right" variant={props.sticky ? 'persistent' : 'temporary'} className={classes.root}>
      <div className={classes.header}>
        {!props.sticky &&
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        }
      </div>
      <Divider />

      <Box className={classes.drawerContainer}>

        {isAuthenticated && 
          <>
            <Box display="flex" flexDirection="column" alignItems="center" mb={1}>
              <Box mt={2} mb={1}>
                <Avatar alt={firstName} className={classes.avatar} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFRCkGVBh06yI__lChDOWOMXLdPbyY6F_Ycw&usqp=CAU"></Avatar>
              </Box>
              <Typography>{firstName}</Typography>
            </Box>
            <Divider className={classes.divider} />
            <MyMenuItem title="Mijn stenen" url="/my/pebbles" icon={<BrushIcon/>}/>
            <MyMenuItem title="Gevonden stenen" url="/my/found-pebbles" icon={<MapIcon/>}/>
            <Divider className={classes.divider} />
            <MyMenuItem title="Nieuwe steen toevoegen" url="/add-pebble" icon={<AddIcon/>} />
            <Divider className={classes.divider} />
            <MyMenuItem title="Afmelden" url="/sign-out" icon={<PowerSettingsNewIcon/>}/>
          </>
        }
        {!isAuthenticated &&
          <>
            <MyMenuItem title="Inloggen" url="/sign-in" icon={<PowerSettingsNewIcon/>}/>
            <MyMenuItem title="Account aanmaken" url="/sign-up" icon={<PersonAddIcon/>}/>
            <MyMenuItem title="Steen registreren" url="/get-started" icon={<AddIcon/>} />
          </>
        }
      </Box>
    </Drawer>
  );
}));
