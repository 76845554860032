import React from 'react';
import { Pebble } from '../../../models/Pebble';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { inject } from 'mobx-react';
import RootStore from '../../../stores/RootStore';
import { DateUtils } from '../../../lib/DateUtils';
//import MessageIcon from '@material-ui/icons/MessageOutlined';
//import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAltOutlined';

// const useStyles = makeStyles(theme => ({
//     infoItem: {
//         borderBottom: 'solid 1px',
//         borderBottomColor: theme.palette.divider
//     },
//     iconInfo: {
//         //fontSize: '.8rem',
//         color: theme.palette.primary.main
//     }
// }))

interface IProps {
  rootStore?: RootStore;
  pebble: Pebble;
}

const MyPebbleRow = inject('rootStore')((props: IProps) => {
  //const classes = useStyles();

  const pebbleUrl = `/my/pebbles/${props.pebble.id}`;
  const onTitleClick = () => props.rootStore?.routerStore.push(pebbleUrl);
  const onImageClick = () => props.rootStore?.routerStore.push(pebbleUrl);
  const onRatingClick = () => props.rootStore?.routerStore.push(`${pebbleUrl}/ratings`);
  //const onMessagesClick = () => props.rootStore?.routerStore.push(`${pebbleUrl}/messages`)
  //const onLikesClick = () => props.rootStore?.routerStore.push(`${pebbleUrl}/likes`)

  // const InfoItem = (p: React.PropsWithChildren<{ value?: string }>) => (
  //     <Box p={1}>
  //         <Box className={classes.infoItem}>
  //             {p.children}
  //             {p.value &&
  //                 <Typography variant="body2">{p.value}</Typography>}
  //         </Box>
  //     </Box>
  // )

  const LastFoundInfo = () => (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="caption">
          <strong>Laatst gevonden</strong>
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <Typography variant="body2">{DateUtils.toDateTimeString(props.pebble.lastFoundTimestamp)}</Typography>
          <Typography variant="body2">{props.pebble.lastFoundLocation?.description}</Typography>
        </Box>
      </Box>
    </Box>
  );

  const FoundCount = () => (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="caption">
          <strong>Gevonden</strong>
        </Typography>
      </Box>
      <Box>
        <Typography variant="h4" component="span">
          {props.pebble.foundCount}
        </Typography>
      </Box>
    </Box>
  );

  const Info = () => (
    <>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Box flexBasis="50%">
          <FoundCount />
        </Box>
        <Box flexBasis="50%">
          <LastFoundInfo />
        </Box>
      </Box>
      {/* <IconInfo /> */}
    </>
  );

  // const IconInfo = () => (
  //     <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className={classes.iconInfo} mt={2}>
  //         <Box display="flex" flexDirection="row" alignItems="center" className="pointer" onClick={onMessagesClick}>
  //             <MessageIcon fontSize="small" />
  //             <Box ml={1}>
  //                 <Typography variant="body2">1234</Typography>
  //             </Box>
  //         </Box>
  //         <Box display="flex" flexDirection="row" alignItems="center" className="pointer" onClick={onLikesClick}>
  //             <ThumbUpAltIcon fontSize="small" />
  //             <Box ml={1}>
  //                 <Typography variant="body2">1234</Typography>
  //             </Box>
  //         </Box>
  //     </Box>
  // )

  return (
    <Paper square={true} elevation={1}>
      <Box p={2}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
          <Box>
            <Typography gutterBottom variant="h5" component="h2" onClick={onTitleClick} className="pointer">
              {props.pebble.title}
            </Typography>
          </Box>
          <Box className="pointer" onClick={onRatingClick}>
            <Rating precision={0.5} value={props.pebble?.rating} size="small" readOnly />
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <img onClick={onImageClick} src={props.pebble.mainPicture} alt={props.pebble.title} style={{ width: '100%' }} className="pointer" />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Info />
            {/* <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            <Button variant="text" color="secondary" onClick={onEdit}>Wijzigen</Button>
                        </Box> */}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
});

export { MyPebbleRow };
