import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import RootStore from '../stores/RootStore';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

interface IProps {
  rootStore?: RootStore;
  loading: boolean;
  text?: string;
}

const Loading = (props: React.PropsWithChildren<IProps>) => {
  const classes = useStyles();

  return (
    <>
      {props.loading && (
        <Box display="flex" flexDirection="column" alignItems="center" className={classes.root}>
          <Box>
            <CircularProgress />
          </Box>
          {props.text && (
            <Box>
              <Typography>{props.text}</Typography>
            </Box>
          )}
        </Box>
      )}
      {!props.loading && <>{props.children}</>}
    </>
  );
};

export { Loading };
