import { makeStyles, ThemeProvider, withTheme } from '@material-ui/core/styles';
import { inject } from 'mobx-react';
import GoogleFontLoader from 'react-google-font-loader';
import { Route, Switch } from 'react-router-dom';
import { MyAppBar } from './components/MyAppBar';
import theme from './Theme';
import Home from './views/public/Home';
import { GetStarted } from './views/public/GetStarted';
import { Lookup } from './views/public/Lookup';
import { SignIn } from './views/public/SignIn';
import { SignOut } from './views/public/SignOut';
import { SignUp } from './views/public/SignUp';
import { PebbleIndex } from './views/public/components/PebbleIndex';
import { MyIndex } from './views/my/MyIndex';
import { Box, useMediaQuery } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { GlobalCss } from './GlobalCss';
import { AccountActivation } from './views/public/AccountActivation';
import { PasswordReset } from './views/public/PasswordReset';
import { MainMenu } from './components/MainMenu';
import { useState } from 'react';
import clsx from 'clsx';
import RootStore from './stores/RootStore';
import { AddPebble } from './views/public/AddPebble';
import { Pebble } from './models/Pebble';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    //	flexGrow: 1
  },
  appBar: {
    //backgroundColor: 'white',
    //color: theme.palette.text.primary
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    //padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    //flexGrow: 1
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10
  }
}));

interface IProps {
  rootStore?: RootStore
}

const App = withTheme(
  inject('rootStore')((props: IProps) => {
    const classes = useStyles();
    const [menuOpen, setMenuOpen] = useState(false);
    const isAuthenticated = props.rootStore?.authStore.isAuthenticated;
    const menuSticky = useMediaQuery(theme.breakpoints.up('md')) && isAuthenticated;
    const routerStore = props.rootStore?.routerStore;

    // useEffect(() => {
    //   setMenuOpen(menuSticky)
    // }, [props.rootStore?.authStore.isAuthenticated]);

    // const [cookieConsentOpen, setCookieConsentOpen] = useState(false);
    // const [cookiePreferencesDialogOpen, setCookiePreferencesDialogOpen] = useState(false);
    //const [cookiePreferences, setCookiePreferences] = useState<CookiePreferences>(new CookiePreferences());

    // useEffect(() => {
    // 	if (!cookiePreferences.load())
    // 		setCookieConsentOpen(true);
    // }, []);

    // const handleCookieAccept = () => {
    // 	cookiePreferences.acceptAllAndSave();
    // 	setCookieConsentOpen(false);
    // }
    // const showCookiePreferences = () => {
    // 	setCookiePreferencesDialogOpen(true);
    // }
    // const handleCookieDialogClose = () => {
    // 	setCookieConsentOpen(false);
    // 	setCookiePreferencesDialogOpen(false);
    // }

    const handleMenuOpen = () => setMenuOpen(true);
    const handleMenuClose = () => setMenuOpen(false);

    return (
      <>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Roboto',
              weights: [300, 400, 500, 700]
            }
          ]}
        />
        <div className={classes.root}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalCss />

            <MyAppBar 
              onMenuClick={handleMenuOpen}
              showMenuButton={!menuSticky && !menuOpen}
              className={clsx(classes.appBar, {
                [classes.appBarShift]: menuSticky,
              })}
            />
            
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: menuSticky,
              })}
            >
              <Box className="page-content">
                <Switch>
                  {/* <Route path="/" component={SoonAvailable} /> */}
                  <Route exact={true} path="/" component={Home} />
                  <Route exact={true} path="/activate" render={() => <AccountActivation />} />
                  <Route exact={true} path="/add-pebble" render={() => <AddPebble onSuccess={(pebble: Pebble) => routerStore?.push(`/my/pebbles/${pebble.id}`)}/>} />
                  <Route exact={true} path="/sign-in" component={SignIn} />
                  <Route exact={true} path="/sign-out" component={SignOut} />
                  <Route exact={true} path="/sign-up" component={SignUp} />
                  <Route exact={true} path="/get-started" component={() => <GetStarted />} />
                  <Route exact={true} path="/get-started1" component={() => <Home />} />
                  <Route exact={false} path="/lookup/:code" render={(p) => <Lookup code={p.match.params.code} />} />
                  <Route exact={true} path="/password-reset/:id" render={props => <PasswordReset passwordResetId={props.match.params.id} />} />
                  <Route exact={true} path="/pebbles/:id" render={(p) => <PebbleIndex id={p.match.params.id} />} />
                  {/*<Route exact={true} path="/about-us/privacy-statement" component={PrivacyStatement} />
                <Route exact={true} path="/about-us/terms-of-use" component={TermsOfUse} />
                <Route exact={true} path="/about-us/cookie-policy" component={CookiePolicy} />
                <Route exact={true} path="/about-us/contact" component={Contact} />
                
                <Route exact={true} path="/signin" component={SignIn} /> */}
                  {/* <Route exact={true} path="/events/new" component={SoonAvailable} /> */}
                  {/* <Route exact={true} path="/event/create" render={props => <NewEvent />} /> */}
                  {/* <Route exact={true} path="/events/:eventId/register" render={props => <EventRegistration eventId={props.match.params.eventId} />} />*/}
                  <Route exact={false} path="/my/" component={MyIndex} />
                </Switch>
              </Box>
            </main>

            <MainMenu open={menuOpen || menuSticky} sticky={menuSticky} onClose={handleMenuClose} />
            

            {/* <Switch>
						<Route exact={true} path="/event/create" />
						<Route exact={true} path="/events/:eventId/register" />
						<Route exact={false} render={props => <Footer onCookieSettingsClick={showCookiePreferences}/> } />
					</Switch>
					<CookieConsent 
						open={cookieConsentOpen}
						onConfirm={handleCookieAccept}
						onCustomize={showCookiePreferences}/>
					<CookiePreferencesDialog 
						open={cookiePreferencesDialogOpen}
						cookiePreferences={cookiePreferences}
						onClose={handleCookieDialogClose} 
					/>*/}
          </ThemeProvider>
        </div>
      </>
    );
  })
);

export { App };
