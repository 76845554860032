import { observer } from 'mobx-react';
import { CustPaper } from '../customizations/CustPaper';
import { Box, Typography } from '@material-ui/core';
import { PebbleLogbook } from '../PebbleLogbook';

interface IProps {
  pebbleId: string;
}

const PebbleLogbookWidget = observer((props: IProps) => (
  <CustPaper>
    <Box mb={2}>
      <Typography variant="h4" component="h2">
        Logboek
      </Typography>
    </Box>
    <PebbleLogbook pebbleId={props.pebbleId} />
  </CustPaper>
));

export { PebbleLogbookWidget };
