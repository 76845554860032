import { Box, TextField, Typography } from '@material-ui/core';
import { Pebble } from '../../../models/Pebble';
import RootStore from '../../../stores/RootStore';
import { inject } from 'mobx-react';
import { StepForm } from '../../../components/wizards/StepForm';
import { LeafletMapComponent, Position } from './LeafletMapComponent';
import { useEffect, useRef, useState } from 'react';
import { Rating } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { AuthMode, AuthModeSelectionPartial } from '../../../components/partials/AuthModeSelectionPartial';
import { SignInDialog, SignInDialogStateType } from '../../../components/SignInDialog';
import { CreateAccountBenefits } from '../../../components/CreateAccountBenefits';
import { CustContainer } from '../../../components/customizations/CustContainer';
import { CustPaper } from '../../../components/customizations/CustPaper';

interface Validations {
  position?: boolean;
  rating?: boolean;
  message?: boolean;
  authMode?: boolean;
}

interface IProps {
  rootStore?: RootStore;
  pebble: Pebble;
  onSuccess: () => void;
}

type StepType = 'auth-mode' | 'welcome' | 'anonymous';

export const LogPebble = inject('rootStore')((props: IProps) => {
  const [position, setPosition] = useState<Position | undefined>(undefined);
  const [rating, setRating] = useState<number>(0);
  const [message, setMessage] = useState<string>();
  const [validations, setValidations] = useState<Validations>();
  const [authMode, setAuthMode] = useState<AuthMode>('');
  const [signInDialogOpen, setSignInDialogOpen] = useState<boolean>(false);
  const [signInDialogState, setSignInDialogState] = useState<SignInDialogStateType>('signin');
  const [skippedSteps, setSkippedSteps] = useState<Map<StepType, boolean>>(new Map([[
    'auth-mode', false,
  ], [
    'welcome', true
  ], [
    'anonymous', true
  ]]));
  
  const authModeNextButtonTexts = new Map<AuthMode, string>([
    ['signIn', 'Inloggen'],
    ['signUp', 'Account aanmaken'],
    ['anonymous', 'Volgende'],
    ['', 'Volgende']
  ]);

  const onNextPosition = async () => {
    const valid = position !== undefined;
    setValidations({ ...validations, position: valid });
    return valid;
  }

  const onNextRating = async () => {
    const valid = rating > 0;
    setValidations({ ...validations, rating: valid });

    return valid;
  }

  const onNextAuthMode = async () => {
    const valid = validate('auth-mode');
    if (valid) {
      if (authMode == 'signIn') {
        setSignInDialogOpen(true);
      } else if (authMode === 'signUp') {
        setSignInDialogState('register');
        setSignInDialogOpen(true);        
      } else if (authMode === 'anonymous') {
        return true;
      }
    }
    return false;
  }

  const onSignInDialogClose = async () => {
    const isAuthenticated = props.rootStore?.authStore.isAuthenticated || false;

    setSkippedSteps(map => new Map(map)
      .set('auth-mode', isAuthenticated)
      .set('welcome', !isAuthenticated)
    );
    setSignInDialogOpen(false);
  }

  const onLocationChange = (position: Position) => {
    setPosition(position);
    setValidations({ ...validations, position: true });
  };

  const onRatingChange = (e: React.ChangeEvent<any>, value: number | null) => {
    setRating(value !== null ? value : 0);
    setValidations({ ...validations, rating: value !== null && value > 0 });
  };
  const onMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value);
  const onFinish = async () => {
    await createLog();
    props.onSuccess();
  };

  const createLog = async () => {
    return await props.rootStore?.logStore.createLog(props.pebble.id, {
      message: message,
      rating: rating,
      position: {
        longitude: position!.longitude,
        latitude: position!.latitude,
        country: position!.country,
        countryCode: position!.countryCode,
        city: position!.city,
        street: position!.street,
        houseNumber: position!.houseNumber,
        municipality: position!.municipality,
        region: position!.region,
        neighbourhood: position!.neighbourhood,
        postalCode: position!.postalCode,
        state: position!.street
      }
    });
  };

  // Determine skipped steps
  useEffect(() => {
    const isAuthenticated = props.rootStore?.authStore.isAuthenticated || false;
    setSkippedSteps(map => new Map(map)
      .set('auth-mode', isAuthenticated)
      .set('anonymous', isAuthenticated || authMode !== 'anonymous')
    );
  }, [
    authMode
  ]);

  const validate = (step: StepType): boolean => {
    let valid = true;
    if (step === 'auth-mode') {
      valid = authMode !== undefined;
      setValidations({...validations, authMode: valid});
    }
    return valid;
  }

  return (
    <>
      <CustContainer>
        <CustPaper>
          <StepForm 
            title="Loggen"
            steps={[{
              title: 'Waar heb je de steen gevonden?',
              component: (
                <Box>
                  <LeafletMapComponent position={position} onChange={onLocationChange} />
                  {validations && validations.position !== undefined && !validations.position && (
                    <Box mt={2}>
                      <Alert severity="error">Geen locatie geselecteerd. Selecteer de locatie waar je de steen heb gevonden door op de kaart hierboven te klikken.</Alert>
                    </Box>
                  )}
                </Box>
              ),
              onNext: onNextPosition
            }, {
              title: 'Welke waardering geef je aan de steen?',
              component: (
                <Box>
                  <Box display="flex" justifyContent="center" m={2}>
                    <Rating precision={0.5} onChange={onRatingChange} value={rating} style={{ fontSize: '3rem' }} />
                  </Box>
                  {validations && validations.rating !== undefined && !validations.rating && (
                    <Box mt={2}>
                      <Alert severity="error">Geef een waardering op door op de sterren te klikken.</Alert>
                    </Box>
                  )}
                </Box>
              ),
              onNext: onNextRating
            }, {
              title: 'Bericht aan kunstenaar',
              component: (
                <Box>
                  <Box mb={2}>
                    <Typography>
                      Dit bericht wordt getoond in het logboek van de steen.
                    </Typography>
                  </Box>
                  <TextField name="message" fullWidth multiline={true} rows={10} variant="filled" value={message} onChange={onMessageChange} />
                </Box>
              )
            }, {
              title: 'Aanmelden',
              component: (
                <AuthModeSelectionPartial
                  value={authMode}
                  anonymousEnabled={true}
                  onChange={setAuthMode}
                  error={validations?.authMode !== undefined && !validations?.authMode}
                />
              ),
              nextButtonText: authModeNextButtonTexts.get(authMode),
              skip: skippedSteps.get('auth-mode'),
              onNext: onNextAuthMode
            }, {
              title: 'Afronden',
              component: (
                <>
                  <Typography>Welkom {props.rootStore?.authStore.user?.firstName}, klik op voltooien om je log af te ronden.</Typography>
                </>
              ),
              skip: skippedSteps.get('welcome')
            }, {
              title: 'Voordelen van een account',
              component: (
                <CreateAccountBenefits />
              ),
              skip: skippedSteps.get('anonymous'),
              nextButtonText: 'Toch anoniem loggen'
            }]}
            onFinish={onFinish}
          >
          </StepForm>
        </CustPaper>
      </CustContainer>
      <SignInDialog 
        open={signInDialogOpen}
        onClose={onSignInDialogClose}
        state={signInDialogState}
      />      
    </>
  );
});
