import { action, observable, computed, makeObservable } from 'mobx';
import { Pebble } from '../models/Pebble';
import RootStore from './RootStore';
import TransportLayer from './TransportLayer';
import { v4 as uuidv4 } from 'uuid';

export class PebbleStore {
  transportLayer: TransportLayer;
  @observable pebbles: Pebble[] = [];
  rootStore: RootStore;

  constructor(rootStore: RootStore, transportLayer: TransportLayer) {
    makeObservable(this);
    this.transportLayer = transportLayer;
    this.rootStore = rootStore;
  }

  @computed get myPebbles(): Pebble[] {
    if (!this.rootStore.authStore.isAuthenticated) return [];

    return (
      this.pebbles
        .filter((p) => p.ownerUserId === this.rootStore.authStore.user!.id)
        // descending
        .sort((a, b) => b.createTimestamp.toMillis() - a.createTimestamp.toMillis())
    );
  }

  @action clear = () => {
    this.pebbles = [];
  };

  @action createPebble = async (data: { title: string; description: string | undefined }): Promise<Pebble> => {
    const res = await this.transportLayer.createPebble({
      ...data,
      ...{ id: uuidv4() }
    });

    const pebble = new Pebble(this);
    pebble.updateFromServer(res);
    this.pebbles.push(pebble);
    return pebble;
  };

  @action loadMyPebbles = async () => {
    if (!this.rootStore.authStore.isAuthenticated) return;
    const ps = await this.transportLayer.fetchPebblesForUser(this.rootStore.authStore.user!.id);
    ps.forEach((p) => {
      this.addOrUpdatePebble(p);
    });
  };

  @action loadPebbleById = async (id: string): Promise<Pebble> => {
    const p = await this.transportLayer.fetchPebbleById(id);
    return this.addOrUpdatePebble(p);
  };

  @action loadPebbleByCode = async (code: string): Promise<Pebble | undefined> => {
    const p = await this.transportLayer.fetchPebbleByCode(code);
    if (!p || p.length !== 1) return undefined;
    return this.addOrUpdatePebble(p[0]);
  };

  @action loadPebblesLastFound = async (count: number): Promise<Pebble[]> => {
    const data = await this.transportLayer.fetchPebblesLastFound(count);
    return data.map((d) => this.addOrUpdatePebble(d));
  };

  getPebble = (id: string): Pebble | undefined => this.pebbles.find((p) => p.id === id);

  get = (pebbleId: string): Pebble | undefined => this.pebbles.find((e) => e.id === pebbleId);

  @action addOrUpdatePebble = (p: any): Pebble => {
    let pebble = this.get(p.id);
    if (pebble) {
      pebble.updateFromServer(p);
    } else {
      // create and push to memcache
      pebble = new Pebble(this);
      pebble.updateFromServer(p);
      this.pebbles.push(pebble);
    }
    return pebble;
  };
}
