import AuthStore from './AuthStore';
import { PebbleStore } from './PebbleStore';
import { ProfileStore } from './ProfileStore';
import { LogStore } from './LogStore';
import RouterStore from './RouterStore';
import TransportLayer from './TransportLayer';
import { AccountStore } from './AccountStore';

export default class RootStore {
  authStore: AuthStore;
  accountStore: AccountStore;
  routerStore: RouterStore;
  pebbleStore: PebbleStore;
  profileStore: ProfileStore;
  logStore: LogStore;

  constructor(routerStore: RouterStore) {
    const transportLayer = new TransportLayer();

    this.routerStore = routerStore;
    this.authStore = new AuthStore(this, transportLayer);
    this.accountStore = new AccountStore(transportLayer);
    this.pebbleStore = new PebbleStore(this, transportLayer);
    this.profileStore = new ProfileStore(this, transportLayer);
    this.logStore = new LogStore(this, transportLayer);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.initStores().then();
  }

  initStores = async () => {
    await this.authStore.initFromLocalStorage();
  };
  clearAllStores = () => {
    this.pebbleStore.clear();
    this.logStore.clear();
  };
}
