import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles, useTheme, withTheme } from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useMediaQuery } from '@material-ui/core';
import RootStore from '../stores/RootStore';
import emailValidator from 'email-validator';
import passwordValidator from '../lib/PasswordValidator';
import { SignUpForm } from './SignUpForm';
import { SignInResult } from '../stores/AuthStore';

export type SignInDialogStateType = 'signin' | 'register' | 'activation-requested' | 'register-success' | 'resetpassword' | 'resetpassword-emailsent' | 'activation-required';

const useStyles = makeStyles((theme) => ({
  caption: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2)
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

interface IProps {
  rootStore?: RootStore;
  open: boolean;
  onClose: () => void;
  state?: SignInDialogStateType
}

const SignInDialog = withTheme(
  inject('rootStore')(
    observer((props: IProps) => {
      const classes = useStyles();
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
      const [state, setState] = useState<SignInDialogStateType>(props.state || 'signin');
      const [email, setEmail] = useState<string>();
      const [password, setPassword] = useState<string>();
      const [signInResult, setSignInResult] = useState<SignInResult | undefined>();
      const [emailValid, setEmailValid] = useState<boolean>();
      const [passwordValid, setPasswordValid] = useState<boolean>();

      useEffect(() => {
        if (props.state) {
          setState(props.state);
        }
      }, [
        props.state
      ])

      useEffect(() => {
        if (email) {
          setEmailValid(emailValidator.validate(email!));
        } else {
          setEmailValid(undefined);
        }
      }, [email]);

      useEffect(() => {
        if (password) {
          setPasswordValid(passwordValidator.validate(password) as boolean);
        } else {
          setPasswordValid(undefined);
        }
      }, [password]);

      useEffect(() => {
        if (signInResult === 'success') {
          close();
        }
        else if (signInResult === 'user-not-activated') {
          setState('activation-required');
        }
      }, [signInResult]);

      const close = () => {
        clear();
        props.onClose();
      };

      const clear = () => {
        setEmail(undefined);
        setPassword(undefined);
      };

      const handleSignIn = async () => {
        if (email && password) {
          const result = await props.rootStore!.authStore.signIn(email, password);
          setSignInResult(result);
        }
      };

      const handleCancelSignIn = () => {
        close();
      };

      const backToSignIn = () => {
        setState('signin');
        setSignInResult(undefined);
      };

      const handleResetPassword = async () => {
        if (email) {
          await props.rootStore!.authStore.transportLayer.requestPasswordReset(email!);
          setState('resetpassword-emailsent');
        }
      };

      const handleSendActivationEmail = async () => {
        if (email) {
          await props.rootStore!.authStore.transportLayer.requestActivation(email);
          setState('activation-requested');
        }
      };

      const showRegistrationForm = () => {
        setState('register');
      };

      const onSuccessfulRegistration = (_uid: string) => {
        setState('activation-requested');
      };

      return (
        <Dialog open={props.open} fullScreen={fullScreen} maxWidth="sm" fullWidth={true} scroll="paper">
          <DialogTitle>
            {state === 'signin' && 'Inloggen'}
            {(state === 'register' || state === 'register-success') && 'Je Rubble-account maken'}
            {state === 'activation-requested' && 'Account activeren'}
            {state === 'activation-required' && 'Helaas, account niet geactiveerd'}
            {(state === 'resetpassword' || state === 'resetpassword-emailsent') && 'Wachtwoord opnieuw instellen'}
          </DialogTitle>

          {state === 'signin' && (
            <>
              {signInResult === 'failed' && (
                <Box bgcolor="error.main" color="white" p={2}>
                  <Typography>Inloggen mislukt. Controleer email en wachtwoord en probeer het opnieuw.</Typography>
                </Box>
              )}
              <DialogContent>
                <TextField id="email" label="Email" required className={classes.textField} onChange={(e) => setEmail(e.target.value)} error={emailValid !== undefined && !emailValid} />
                <TextField
                  id="password"
                  label="Wachtwoord"
                  type="password"
                  required
                  className={classes.textField}
                  onChange={(e) => setPassword(e.target.value)}
                  error={passwordValid !== undefined && !passwordValid}
                  fullWidth
                />
                <Box display="flex" justifyContent="space-between">
                  <Button variant="text" color="primary" size="small" style={{ textTransform: 'none' }} onClick={() => setState('resetpassword')}>
                    Wachtwoord vergeten?
                  </Button>
                  <Button variant="text" color="primary" size="small" style={{ textTransform: 'none' }} onClick={showRegistrationForm}>
                    Ik ben nieuw en wil mij registreren
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button variant="text" color="primary" onClick={handleCancelSignIn}>
                  Annuleren
                </Button>
                <Button variant="contained" color="primary" onClick={handleSignIn}>
                  Inloggen
                </Button>
              </DialogActions>
            </>
          )}
          {state === 'register' && (
            <DialogContent>
              <SignUpForm showTitle={false} onSuccessful={onSuccessfulRegistration} onSignInClick={backToSignIn} />
            </DialogContent>
          )}
          {state === 'activation-requested' && (
            <>
              <DialogContent>
                <Typography>We hebben een activatie link gestuurd naar je email adres. Volg de instructies in de email en log daarna in.</Typography>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="primary" onClick={backToSignIn}>
                  Inloggen
                </Button>
              </DialogActions>
            </>
          )}
          {state === 'activation-required' && (
            <>
              <DialogContent>
                <Typography paragraph={true}>
                  We hebben je eerder een email gestuurd om je account te activeren. Klik om de link in de email om je account te activeren.
                </Typography>
                <Typography paragraph={true}>
                  Geen email ontvangen? Misschien dat de email in je spam terechtgekomen is. Wil je dat voor ons controleren?
                </Typography>
                <Typography paragraph={true}>
                  Ook geen mail in je spam, klik op de knop "Verstuur activatie email" en we verzenden direct een nieuwe email.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button variant="text" color="primary" onClick={backToSignIn}>
                  Terug naar inloggen
                </Button>
                <Button color="primary" variant="contained" onClick={handleSendActivationEmail}>
                  Verstuur activatie email
                </Button>
              </DialogActions>
            </>
          )}
          {state === 'resetpassword' && (
            <>
              <DialogContent>
                <Typography>Vul je email adres in. We sturen je een email om je wachtwoord opnieuw in te stellen.</Typography>
                <TextField id="email" label="Email" className={classes.textField} defaultValue={email} onChange={(e) => setEmail(e.target.value)} fullWidth required />
              </DialogContent>
              <DialogActions>
                <Button variant="text" color="primary" onClick={backToSignIn}>
                  Terug naar inloggen
                </Button>
                <Button color="primary" variant="contained" onClick={handleResetPassword}>
                  Verstuur email
                </Button>
              </DialogActions>
            </>
          )}
          {state === 'resetpassword-emailsent' && (
            <>
              <DialogContent>
                <Typography>We hebben je een email verzonden om je wachtwoord opnieuw in te stellen. Volg de instructies in de email en log daarna in.</Typography>
              </DialogContent>
              <DialogActions>
                <Button color="primary" variant="contained" onClick={backToSignIn}>
                  Inloggen
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      );
    })
  )
);

export { SignInDialog };
