import { action, observable, makeObservable } from 'mobx';
import RootStore from './RootStore';
import TransportLayer from './TransportLayer';
import { ProfileFoundPebble } from '../models/ProfileFoundPebble';

export class ProfileStore {
  transportLayer: TransportLayer;
  @observable foundPebbles: ProfileFoundPebble[] = [];
  rootStore: RootStore;

  constructor(rootStore: RootStore, transportLayer: TransportLayer) {
    makeObservable(this);
    this.transportLayer = transportLayer;
    this.rootStore = rootStore;
  }

  @action clear = async () => {
    this.foundPebbles = [];
  };

  loadProfile = async (userId: string) => {
    await this.fetchFoundPebblesForUser(userId, 1, 50);
  };

  fetchFoundPebblesForUser = async (userId: string, page: number, pageSize: number): Promise<ProfileFoundPebble[]> => {
    const foundPebblesRes = await this.transportLayer.fetchProfileFoundPebbles(userId, page, pageSize);
    const result = foundPebblesRes.map((data: any) => {
      const item = new ProfileFoundPebble(this);
      item.updateFromServer(data);

      const pebble = this.rootStore.pebbleStore.addOrUpdatePebble(data.pebble);
      item.setPebble(pebble);

      return item;
    }) as ProfileFoundPebble[];
    return result;
  };
}
