import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import React from "react";
import MapIcon from '@material-ui/icons/Map';
import ViewListIcon from '@material-ui/icons/ViewList';
import BrushIcon from '@material-ui/icons/Brush';

export const CreateAccountBenefits = () => (
  <>
    <Typography>
      Wist je dat je met een account geniet van een paar extra voordelen?
    </Typography>
    <Box>
      <List>
        <ListItem>
          <ListItemIcon>
            <ViewListIcon />
          </ListItemIcon>
          <ListItemText>Overzicht van al je gevonden stenen en locaties</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
              <BrushIcon />
          </ListItemIcon>
          <ListItemText>Zelfgemaakte stenen toevoegen en de zwerftocht bekijken</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
              <MapIcon />
          </ListItemIcon>
          <ListItemText>Zwerftocht van alle stenen zichtbaar op de kaart</ListItemText>
        </ListItem>
      </List>
    </Box>
  </>
)
