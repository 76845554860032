import { Box, Typography, makeStyles } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Pebble } from '../models/Pebble';
import clsx from 'clsx';
import RoomIcon from '@material-ui/icons/Room';
import RootStore from '../stores/RootStore';
import { inject } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px'
  },
  navigationButton: {
    '&::before': {
      fontSize: '30px',
      color: theme.palette.primary.main
    },
    width: '30px',
    height: '30px',
    zIndex: 10
  },
  titleText: {
    //color: theme.palette.primary.main
  },
  locationText: {
    color: theme.palette.text.secondary
  }
}));

interface IProps {
  rootStore?: RootStore;
  pebbles: Pebble[];
}

const PebbleCarousel = inject('rootStore')((props: IProps) => {
  const classes = useStyles();

  const Item = (itemProps: { pebble: Pebble }) => {
    const navigateToPebble = () => {
      props.rootStore?.routerStore.push(`/pebbles/${itemProps.pebble.id}`);
    };
    const onTitleClick = () => navigateToPebble();
    const onImageClick = () => navigateToPebble();
    const onLocationClick = () => navigateToPebble();

    return (
      <Box ml={2} mr={2}>
        <Typography
          onClick={onTitleClick}
          className={clsx(classes.titleText, 'pointer')}
          variant="h6"
          component="h2"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {itemProps.pebble.title} fsfd dsf sdf dsf dsf fds fds dsf sffd dsf dsf dsf
        </Typography>
        <img onClick={onImageClick} src={itemProps.pebble.mainPicture} alt={itemProps.pebble.title} style={{ width: '100%' }} className="pointer" />
        <Box onClick={onLocationClick} mt={1} display="flex" className={clsx(classes.locationText, 'pointer')}>
          <RoomIcon />
          <Typography variant="subtitle1">{itemProps.pebble.lastFoundLocation?.description}</Typography>
        </Box>
      </Box>
    );
  };

  const PrevButton = (btnProps: any) => <div className={clsx(btnProps.className, classes.navigationButton)} style={{ ...btnProps.style, display: 'block' }} onClick={btnProps.onClick} />;

  const NextButton = (btnProps: any) => <div className={clsx(btnProps.className, classes.navigationButton)} style={{ ...btnProps.style, display: 'block' }} onClick={btnProps.onClick} />;

  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    dots: true,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {props.pebbles.map((pebble) => (
        <Item key={pebble.id} pebble={pebble} />
      ))}
    </Slider>
  );
});

export { PebbleCarousel };
