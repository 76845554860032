import { makeStyles } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      fontFamily: 'Arial',
      padding: 0,
      margin: 0
    },
    body: {
      fontFamily: 'Arial',
      padding: 0,
      margin: 0,
      width: '100%',
      backgroundColor: theme.palette.background.default
    },
    '.page-content': {
      paddingTop: '64px' // app bar correction
    },
    '.page-title': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    '.paper-padding': {
      padding: theme.spacing(2)
    },
    '.footer': {
      position: 'absolute',
      bottom: 0,
      width: '100%'
    },
    '.footer-sticky': {
      position: 'fixed',
      bottom: 0,
      width: '100%'
    },
    '.card-info': {
      backgroundColor: blue['100'],
      borderColor: blue['300']
    },
    '.section': {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: '#fafafa'
    },
    '.section-highlight': {
      backgroundColor: grey[200]
    },
    '.pointer': {
      cursor: 'pointer'
    }
  }
}));

const GlobalCss = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles();
  return <></>;
};

export { GlobalCss };
