import { Box, Container, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import RootStore from '../stores/RootStore';
import { CustContainer } from './customizations/CustContainer';

interface IProps {
  rootStore?: RootStore;
}

const AuthenticatedArea = inject('rootStore')(
  observer((props: React.PropsWithChildren<IProps>) => (
    <>
      {props.rootStore!.authStore.isAuthenticated && props.children}
      {!props.rootStore!.authStore.isAuthenticated && (
        <>
          <CustContainer>
            <Box className="page-title">
              <Typography variant="h4">Inloggen vereist</Typography>
            </Box>
          </CustContainer>
        </>
      )}
    </>
  ))
);

export { AuthenticatedArea };
