import React from 'react';
import { Box, Paper, PaperProps } from '@material-ui/core';

interface IProps extends PaperProps {
  padding?: boolean;
}

const CustPaper = (props: React.PropsWithChildren<IProps>) => (
  <Paper square={true} elevation={1} {...props}>
    <>
      {props.padding && <Box p={2}>{props.children}</Box>}
      {!props.padding && <>{props.children}</>}
    </>
  </Paper>
);

CustPaper.defaultProps = {
  padding: true
};

export { CustPaper };
