import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Box, Button, Hidden, IconButton } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import RootStore from '../stores/RootStore';
import { SignInDialog } from './SignInDialog';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { MainMenu } from './MainMenu';
import clsx from 'clsx';
import { CustContainer } from './customizations/CustContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default,
    //zIndex: theme.zIndex.drawer + 50
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: 'white',
    marginLeft: theme.spacing(2)
  }
}));

class IProps {
  rootStore?: RootStore;
  showMenuButton?: boolean = false;
  onMenuClick?: () => void;
  className?: string;
}

export const MyAppBar = inject('rootStore')(
  observer((props: IProps) => {
    const classes = useStyles();
    const [signInDialogOpen, setSignInDialogOpen] = useState<boolean>(false);
    const [mainMenuOpen, setMainMenuOpen] = useState(false);

    const onSignInDialogClose = () => {
      setSignInDialogOpen(false);
      if (props.rootStore?.authStore.isAuthenticated) {
        props.rootStore?.routerStore.push('/my');
      }
    };
    const isAuthenticated = props.rootStore?.authStore.isAuthenticated || false;

    const RegisterPebbleButton = () => (
      <Link to="/get-started" className={classes.link}>
        <Button variant="contained" color="secondary" className={classes.button}>
          Steen registreren
        </Button>
      </Link>
    );

    const CreateAccountButton = () => (
      <Link to="/sign-up" className={classes.link}>
        <Button className={classes.button}>
          Account aanmaken
        </Button>
      </Link>
    );

    const LoginButton = () => (
      <Link to="/sign-in" className={classes.link}>
        <Button aria-haspopup="true" className={classes.button}>
          Inloggen
        </Button>
      </Link>
    );

    const onMenuClick = () => {
      if (props.onMenuClick) props.onMenuClick();
    };
    const onMainMenuClose = () => {
      setMainMenuOpen(false);
    };
    return (
      <>
        <AppBar position="fixed" className={clsx(props.className, classes.root)} elevation={0}>
          <CustContainer>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ height: '64px' }}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ height: '64px' }}>
                <Box>
                  <Link to='/'>
                    <img src={process.env.PUBLIC_URL + '/assets/logo.png'} alt="rubble logo" height="50px" className={`pointer`}/>
                  </Link>
                </Box>
              </Box>

              {!isAuthenticated && 
                <>
                  <Hidden xsDown>
                    <Box>
                      {!props.rootStore?.authStore.isAuthenticated && 
                        <>
                          <RegisterPebbleButton />
                          <CreateAccountButton />
                          <LoginButton />
                        </>
                      }
                    </Box>
                  </Hidden>
                  <Hidden smUp>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick}>
                      {mainMenuOpen && <CloseIcon />}
                      {!mainMenuOpen && <MenuIcon />}
                    </IconButton>
                  </Hidden>
                </>
              }

              {isAuthenticated && props.showMenuButton && (
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="stretch">
                  <IconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick}>
                    {mainMenuOpen && <CloseIcon />}
                    {!mainMenuOpen && <MenuIcon />}
                  </IconButton>
                </Box>
              )}
            </Box>
          </CustContainer>
        </AppBar>
        <MainMenu open={!isAuthenticated && mainMenuOpen} onClose={onMainMenuClose} />
        <SignInDialog open={signInDialogOpen} onClose={onSignInDialogClose} />
      </>
    );
  })
);
